import { useIsAuthenticated } from "@azure/msal-react";
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from '@mui/icons-material/Menu';
import SchemaIcon from '@mui/icons-material/Schema';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../AppContext";
import { PowerBiDataset, PowerBiReport } from '../models/models';
import DataLoading from './DataLoading';
const AnimatedListItem = motion(ListItem);
const AnimatedMenuIcon = motion(MenuIcon);
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const leftNavOuterBoxStyles = {
    width: 1,
    display: "flex"
};

const avatarStyles = {
    width: "22px",
    height: "22px",
    m: 0,
    mt: "2px",
    cursor: 'pointer',
    outline: 'none'
};

const leftNavListStyles = {
    m: 0,
    p: 0,
    pb: "8px",
    width: 1
};

interface LeftNavProps {
    leftNavExpanded: boolean;
    setLeftNavExpanded: (LeftNavExpanded: boolean) => void;
}

const LeftNav = ({ setLeftNavExpanded, leftNavExpanded }: LeftNavProps) => {

    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { embeddingData, isDbizAdmin, isSuperUser, tenantTheme } = useContext(AppContext);
    const expandedLeftNavWidth = 280;

    const typoGraphy1Styles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        cursor: 'pointer'
    };

    const leftNavInnerBoxLeftStyles = {
        color: tenantTheme?.Color5 || '#00ffa9',
        width: "48px",
        minWidth: "48px",
        textAlign: "center",
        p: 0,
        pt: "4px",
    };

    const divider1Styles = {
        backgroundColor: tenantTheme?.Color3 || '#FFFFFF',
    };

    const leftNavTenantNameStyles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        fontSize: "18px",
        width: 1,
        pt: "4px"
    };

    const leftNavHeaderStyles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        fontSize: "18px",
        width: 1,
        fontWeight: "200",
        pl: 0,
        mb: 0,
        my: 0,
        pt: "2px"
    };

    const leftNavTopBoxStyles = {
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        width: 1,
        overflowX: "hidden"
    };

    const leftNavInnerBoxTenantNameStyles: SxProps = {
        pt: "2px",
        pl: "4px",
        width: leftNavExpanded ? 1 : 0,
        maxHeight: "28px;"
    };

    const leftNavInnerBoxRightStyles: SxProps = {
        color: tenantTheme?.Color4 || '#000000',
        py: 0,
        pl: "4px",
        width: leftNavExpanded ? 1 : 0
    };

    const drawerStyles: SxProps = {
        width: expandedLeftNavWidth,
        pb: 3,
        display: (isAuthenticated && embeddingData.tenantName && !embeddingData.workspaceArtifactsLoading) ? "flex" : "none"
    };

    const drawerPaperStyles: SxProps = {
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        width: expandedLeftNavWidth,
        borderRight: "1px solid #444444"
    };

    return (
        <>
            <Drawer
                variant={'temporary'}
                open={leftNavExpanded}
                onClose={() => { setLeftNavExpanded(false) }}
                anchor='left'
                sx={drawerStyles}
                PaperProps={{ sx: drawerPaperStyles }}
            >
                <Box sx={leftNavTopBoxStyles}>
                    <Box sx={leftNavOuterBoxStyles} >
                        <Box sx={leftNavInnerBoxLeftStyles} >
                            <AnimatedMenuIcon
                                whileHover={{ scale: 1.3 }}
                                whileTap={{ scale: 0.9 }}
                                sx={avatarStyles}
                                onClick={() => { setLeftNavExpanded(false) }} />
                        </Box>
                        <Box sx={leftNavInnerBoxTenantNameStyles} >
                            <Typography sx={leftNavTenantNameStyles} variant='h5' fontSize={20} >{embeddingData.tenantName}</Typography>
                        </Box>
                    </Box>
                    <Divider sx={divider1Styles} />
                    <Box sx={leftNavOuterBoxStyles} >
                        <Box sx={leftNavInnerBoxLeftStyles} >
                            <AssessmentIcon sx={avatarStyles} />
                        </Box>
                        <AnimatePresence>
                            {leftNavExpanded &&
                                <Box sx={leftNavInnerBoxRightStyles}>
                                    <Typography sx={leftNavHeaderStyles} variant='subtitle1' >View Reports</Typography>
                                    {embeddingData.workspaceArtifactsLoading && <DataLoading />}
                                    {!embeddingData.workspaceArtifactsLoading && (
                                        <List disablePadding dense sx={leftNavListStyles}>
                                            {embeddingData.reports?.map((report: PowerBiReport) => (
                                                <AnimatedListItem
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    whileHover={{ scale: 1.05 }}
                                                    transition={{ duration: 0.3 }}
                                                    key={report.id}
                                                    sx={{
                                                        backgroundColor: (document.URL.includes(report.id)) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                        py: "4px", pl: "6px", width: 1,
                                                        fontWeight: "100",
                                                        fontSize: "14px",
                                                        borderLeft: (document.URL.includes(report.id)) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                        ':hover': {
                                                            backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setLeftNavExpanded(false)
                                                        navigate("/reports/" + report.id);
                                                    }} >
                                                    <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                        {report.name}
                                                    </Typography>
                                                </AnimatedListItem>
                                            ))}
                                        </List>
                                    )}
                                </Box>
                            }
                        </AnimatePresence>
                    </Box>
                    <Divider sx={divider1Styles} />
                    {embeddingData.userCanCreate &&
                        <>
                            <Box sx={leftNavOuterBoxStyles}>
                                <Box sx={leftNavInnerBoxLeftStyles}>
                                    <SchemaIcon sx={avatarStyles} />
                                </Box>
                                <AnimatePresence>
                                    {leftNavExpanded &&
                                        <Box sx={leftNavInnerBoxRightStyles}>
                                            <Typography sx={leftNavHeaderStyles} variant='subtitle1' >Create Report</Typography>
                                            {embeddingData.workspaceArtifactsLoading && <DataLoading />}
                                            {!embeddingData.workspaceArtifactsLoading && (
                                                <List disablePadding dense sx={leftNavListStyles}>
                                                    {embeddingData.datasets?.map((dataset: PowerBiDataset) => (
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key={dataset.id}
                                                            sx={{
                                                                backgroundColor: (document.URL.includes(dataset.id)) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                py: "4px",
                                                                pl: "6px",
                                                                width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                borderLeft: (document.URL.includes(dataset.id)) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false)
                                                                navigate("/reports/" + dataset.id);
                                                            }} >
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                {dataset.name}
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    ))}
                                                </List>
                                            )}
                                        </Box>
                                    }
                                </AnimatePresence>
                            </Box>
                        </>}
                    <Divider sx={divider1Styles} />
                    {(isSuperUser || isDbizAdmin) &&
                        <>
                            <Box sx={leftNavOuterBoxStyles} >
                                <Box sx={leftNavInnerBoxLeftStyles} >
                                    <SettingsIcon sx={avatarStyles} />
                                </Box>
                                <AnimatePresence>
                                    {leftNavExpanded &&
                                        <Box sx={leftNavInnerBoxRightStyles}>
                                            <Typography sx={leftNavHeaderStyles} variant='subtitle1' >Management</Typography>
                                            {embeddingData.workspaceArtifactsLoading && <DataLoading />}
                                            {!embeddingData.workspaceArtifactsLoading && (
                                                <List disablePadding dense sx={leftNavListStyles}>
                                                    {isSuperUser &&
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key="usermanagement"
                                                            sx={{
                                                                py: "4px", pl: "6px", width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                backgroundColor: (document.URL.includes('usermanagement')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                borderLeft: (document.URL.includes('usermanagement')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false);
                                                                navigate("/usermanagement");
                                                            }}>
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                User Management
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    }
                                                    {(isDbizAdmin) &&
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key="tenantManagement"
                                                            sx={{
                                                                py: "4px", pl: "6px", width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                backgroundColor: (document.URL.includes('tenantmanagement')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                borderLeft: (document.URL.includes('tenantmanagement')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false)
                                                                navigate("/tenantmanagement");
                                                            }} >
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                Tenant Management
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    }
                                                    {isDbizAdmin &&
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key="dbizmanagement"
                                                            sx={{
                                                                py: "4px", pl: "6px", width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                backgroundColor: (document.URL.includes('dbizmanagement')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                borderLeft: (document.URL.includes('dbizmanagement')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false)
                                                                navigate("/dbizmanagement");
                                                            }} >
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                DBIZ Management
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    }
                                                </List>
                                            )}
                                        </Box>
                                    }
                                </AnimatePresence>
                            </Box>
                        </>
                    }
                    <Divider sx={divider1Styles} />
                    {((isDbizAdmin === true || embeddingData.isRequestModuleEnabled) === true) && (
                        <>
                            <Box sx={leftNavOuterBoxStyles}>
                                <Box sx={leftNavInnerBoxLeftStyles} >
                                    <SupportAgentIcon sx={avatarStyles} />
                                </Box>
                                <AnimatePresence>
                                    {leftNavExpanded &&
                                        <Box sx={leftNavInnerBoxRightStyles}>
                                            <Typography sx={leftNavHeaderStyles} variant='subtitle1' >Support</Typography>
                                            {embeddingData.workspaceArtifactsLoading && <DataLoading />}
                                            {!embeddingData.workspaceArtifactsLoading && (
                                                <List disablePadding dense sx={leftNavListStyles}>
                                                    <AnimatedListItem
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        whileHover={{ scale: 1.05, }}
                                                        transition={{ duration: 0.3 }}
                                                        key="userrequest"
                                                        sx={{
                                                            py: "4px", pl: "6px", width: 1,
                                                            fontWeight: "100",
                                                            fontSize: "14px",
                                                            backgroundColor: (document.URL.includes('userrequest')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                            borderLeft: (document.URL.includes('userrequest')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                            ':hover': {
                                                                backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            setLeftNavExpanded(false);
                                                            navigate("/userrequest");
                                                        }}>
                                                        <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                            User Request
                                                        </Typography>
                                                    </AnimatedListItem>
                                                    {(isDbizAdmin || isSuperUser) &&
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key="requestapproval"
                                                            sx={{
                                                                py: "4px", pl: "6px", width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                backgroundColor: (document.URL.includes('requestapproval')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                borderLeft: (document.URL.includes('requestapproval')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false)
                                                                navigate("/requestapproval");
                                                            }} >
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                Approval
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    }
                                                    {isDbizAdmin &&
                                                        <AnimatedListItem
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            whileHover={{ scale: 1.05, }}
                                                            transition={{ duration: 0.3 }}
                                                            key="dbizreqmanagement"
                                                            sx={{
                                                                py: "4px", pl: "6px", width: 1,
                                                                fontWeight: "100",
                                                                fontSize: "14px",
                                                                backgroundColor: (document.URL.includes('dbizreqmanagement')) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                                borderLeft: (document.URL.includes('dbizreqmanagement')) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                                ':hover': {
                                                                    backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setLeftNavExpanded(false)
                                                                navigate("/dbizreqmanagement");
                                                            }} >
                                                            <Typography variant="inherit" noWrap sx={typoGraphy1Styles}>
                                                                DBIZ Request Mgmt.
                                                            </Typography>
                                                        </AnimatedListItem>
                                                    }
                                                </List>
                                            )}
                                        </Box>
                                    }
                                </AnimatePresence>
                            </Box>
                            <Divider sx={divider1Styles} />
                        </>
                    )}
                </Box>
            </Drawer >
        </>
    )
}

export default LeftNav