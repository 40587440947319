import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { AppContext } from '../AppContext';


const NotificationContainer = () => {

    const { tenantTheme } = useContext(AppContext);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="dark"
                toastStyle={{ backgroundColor: (tenantTheme?.Color1 || '#0d4557') }}
                bodyStyle={{ backgroundColor: (tenantTheme?.Color1 || '#0d4557') }}
            />
        </>
    )
}

export default NotificationContainer;