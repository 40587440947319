import { useIsAuthenticated } from "@azure/msal-react";
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const alertStyles = {
    border: 1,
    padding: 2,
    mx: 2
}

const typoGraphy1Styles = {
    my: 3
}

const PageNotAccessible = () => {
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
        return (
            <Container maxWidth={false}>
                <Typography variant='h5' component="h2" sx={typoGraphy1Styles} >Page not accessible to anonymous user</Typography>
                <Alert sx={alertStyles} severity="error" >Please login by clicking the <strong>LOGIN</strong> link in the upper right of this page.</Alert>
            </Container>
        )
    }
}

export default PageNotAccessible;