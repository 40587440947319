import Article from '@mui/icons-material/Article';
import Fullscreen from '@mui/icons-material/Fullscreen';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SaveAs from '@mui/icons-material/SaveAs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { AnimatePresence, motion } from 'framer-motion';
import powerbi from "powerbi-client";
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../AppContext";
require('powerbi-models'); // ensure Power BI JavaScript API has loaded
require('powerbi-client'); // ensure Power BI JavaScript API has loaded


const toolBarContainerBoxStyles = {
    backgroundColor: "white",
    width: 1,
    p: 0,
    m: 0
}

const toolBarStyles = {
    p: 0,
    m: 0,
    minHeight: "32px"
}

const menuButtonStyles = {
    color: "#555555",
    fontSize: "9px",
    ml: 1
}

const menuItemStyles = {
    fontSize: "11px",
    px: 1.5,
    py: 0,
    m: 0
}

const divider1Styles = {
    ml: "auto"
}

const divider2Styles = {
    mr: 1
}

const saveAsIconStyles = {
    mr: 1
}

interface NewReportToolbarProps {
    report: powerbi.Embed;
}

const NewReportToolbar = ({ report }: NewReportToolbarProps) => {

    const [anchorElementFile, setAnchorElementFile] = useState<null | HTMLElement>(null);
    const [openSaveAsDialog, setOpenSaveAsDialog] = useState(false);
    const [newReportName, setNewReportName] = useState("");
    const refReportName = useRef<HTMLInputElement>(null);
    const { isSuperUser } = useContext(AppContext);
    const navigate = useNavigate();

    const onFileSaveAs = () => {
        setAnchorElementFile(null);
        setNewReportName("")
        setOpenSaveAsDialog(true);
    };

    const onReportFullscreen = () => {
        report.fullscreen();
    };

    return (
        <>
            <Box sx={toolBarContainerBoxStyles} >
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        <Toolbar disableGutters variant='dense' sx={toolBarStyles} >
                            <Button
                                startIcon={<Article />}
                                endIcon={<KeyboardArrowDown />}
                                sx={menuButtonStyles}
                                onClick={(event) => { setAnchorElementFile(event.currentTarget); }} >
                                File
                            </Button>
                            <Menu sx={menuItemStyles} anchorEl={anchorElementFile} open={Boolean(anchorElementFile)} onClose={() => { setAnchorElementFile(null); }} >
                                <MenuItem sx={menuItemStyles} onClick={onFileSaveAs} disableRipple>
                                    <SaveAs sx={saveAsIconStyles} /> Save As
                                </MenuItem>
                            </Menu>
                            <Divider orientation='vertical' flexItem />
                            <Divider orientation='vertical' flexItem sx={divider1Styles} />
                            {isSuperUser && (
                                <>
                                    <Button
                                        startIcon={<ManageAccountsIcon />}
                                        sx={menuButtonStyles}
                                        onClick={(event) => { navigate("/usermanagement"); }} >
                                        User Management
                                    </Button>
                                    <Divider orientation='vertical' flexItem />
                                </>
                            )}
                            <Button startIcon={<Fullscreen />} sx={menuButtonStyles} onClick={onReportFullscreen}  >Full Screen</Button>
                            <Divider orientation='vertical' flexItem sx={divider2Styles} />
                        </Toolbar>
                    </motion.div>
                </AnimatePresence>
            </Box >

            <Dialog open={openSaveAsDialog} onClose={() => { setOpenSaveAsDialog(false); }} >
                <DialogTitle>Enter report name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You need to give this new report a name.
                    </DialogContentText>
                    <TextField
                        onChange={(event) => { setNewReportName(event.target.value) }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="New Report Name"
                        type="text"
                        value={newReportName}
                        fullWidth
                        variant="standard"
                        inputRef={refReportName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={newReportName === ""} onClick={async () => {
                        setOpenSaveAsDialog(false);
                        await report.saveAs({ name: newReportName });
                    }}>Save</Button>
                    <Button onClick={() => { setOpenSaveAsDialog(false); }}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default NewReportToolbar