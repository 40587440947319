import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, MenuItem, Select, Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from '../../AppContext';
import { MangeTenantUsersRowData, Reports, TenantRoles } from '../../models/models';
import AppOwnsDataWebApi from '../../services/AppOwnsDataWebApi';
import { CustomLoader, languages } from '../CommonComponents';
import Unauthenticated from '../Unauthenticated';
import Unauthorized from '../Unauthorized';

import DeleteIcon from '@mui/icons-material/Delete';
import { AnimatePresence, motion } from 'framer-motion';
import ReportAssignment from "../ReportAssignment";
import UserCreation from "../UserCreation";


const mainContainerStyles = {
    display: 'flex',
    flexDirection: 'column'
}

const pageTitleStyles = {
    mt: 2,
    mb: "6px",
    textAlign: 'center',
    fontWeight: '500px'
}

const mainContainerGridStyles = {
    display: 'flex',
    height: '89vh'
}

const container1Styles = {
    backgroundColor: '#f0f0f0',
    p: 2,
    boxShadow: '0 10px 6px rgb(0 0 0 / 0.2);',
    height: '100%'
}

const box1Styles = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingTop: '10px',
    paddingBottom: '10px'
}

const box2Styles = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingTop: '30px'
}

const box3Styles = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingTop: '30px',
    paddingBottom: '30px'
}

const box4Styles = {
    display: 'flex',
    flexDirection: 'column'
}


const filterIconStyles = {
    color: '#0d4557',
    marginRight: '5px'
}

const applyFilterTextStyles = {
    fontWeight: 'lighter'
}

const filtersIconStyles = {
    color: '#0d4557',
    marginRight: '5px',
}

const filtersTypoGraphyStyles = {
    fontWeight: 'lighter'
}

const iconStyles = {
    color: '#0d4557'
}

const searchInputs1Styles = {
    ml: 1,
    flex: 1
}

const searchInputs2Styles = {
    color: '#0d4557',
    width: '100%'
}


const DBIZManagement = () => {
    const navigate = useNavigate();
    const [pending, setPending] = React.useState(true);
    const [manageTenantUserDetails, setTenantUserDetails] = React.useState([]);
    const [manageFilteredTenantUserDetails, setFilteredTenantUserDetails] = React.useState([]);
    const [selectedTenant, setSelectedTenant] = React.useState('');
    const [searchedUserName, setSearchedUserName] = React.useState('');
    const [searchedLoginId, setSearchedLoginId] = React.useState('');
    const [tenantsList, setTenantsList] = React.useState([]);
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { setDisplayMainLoader, tenantTheme, embeddingData } = useContext(AppContext);
    const [hasPermissions, setHasPermissions] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //Checking Permissions

    const [userCreationModalOpen, setUserCreationModalOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);

    const [showReportsTab, setShowReportsTab] = React.useState(false);
    const [currentUserForReportAssignment, setCurrentUserForReportAssignment] = React.useState("");
    const [manageReports, setManageReports] = React.useState<Reports[]>([]);
    const [manageFilteredReports, setFilteredManageReports] = React.useState<Reports[]>([]);
    const [assignedReportIds, setAssignedReportIds] = React.useState([]);
    const [configuredTenantRoles, setConfiguredTenantRoles] = React.useState<TenantRoles[]>([]);

    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = React.useState<boolean>(false);
    const [selectedUserToDelete, setSelectedUserToDelete] = React.useState<string>('');

    const applyFiltersButtonStyles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        mt: 2,
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginLeft: '8px',
        },
        '&:hover': {
            backgroundColor: tenantTheme?.Color1 || '#0d4557',
        }
    }

    const container2Styles = {
        backgroundColor: 'white',
        borderRadius: '6px',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
        marginBottom: '15px',
        borderLeft: '4px solid ' + (tenantTheme?.Color5 || '#00ffa9')
    }

    const clearFiltersButtonStyles = {
        marginTop: '5px',
        backgroundColor: tenantTheme?.Color2 || '#018b8b',
        color: tenantTheme?.Color4 || '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginLeft: '8px',
        },
        '&:hover': {
            backgroundColor: tenantTheme?.Color2 || '#018b8b',
        }
    }

    const addNewUserButtonStyles = {
        marginTop: '5px',
        backgroundColor: tenantTheme?.Color5 || '#00ffa9',
        color: tenantTheme?.Color1 || '#0d4557',
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginLeft: '8px',
        },
        '&:hover': {
            backgroundColor: tenantTheme?.Color5 || '#00ffa9',
        }
    }

    const handleDeleteClick = (loginId: string) => {
        setSelectedUserToDelete(loginId);
        setIsDeleteUserModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            if (!selectedUserToDelete) {
                toast.error("Please Select the User to Delete!");
                return;
            }

            setIsDeleteUserModalOpen(false);
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.DeleteUser(selectedUserToDelete);
            if (response === true) {
                await FetchMangeUserDetails();
                toast.success("User Deleted");
            }
            else {
                toast.error("Unable to Delete this User. Please make sure there is no data associated with his user");
            }

            setDisplayMainLoader(false);
        } catch (e) {
            console.log(e);
            toast.error("Unable to Delete this User. Please make sure there is no data associated with his user");
            setDisplayMainLoader(false);
        }
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteUserModalOpen(false);
    };

    const handleNewUserCreationClickOpen = () => {
        setUserName('');
        setEmail('');
        setShowValidationMessage(false);
        setUserCreationModalOpen(true);
    };

    const UpdateReportLanguageCode = async (loginId: string, languageCode: string) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.UpdateUserReportLanguageCode(loginId, languageCode);
            if (response === true) {
                embeddingData.setReportLanguageCode(languageCode);
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsersAdmin(),
                    {
                        success: 'Report Language Updated',
                        error: 'An Error Occured'
                    }
                );
                setTenantUserDetails([...response.users]);
            }
            else {
                toast.error("An Unexpected Error Occured!");
            }
            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    useEffect(() => {
        filterSearch();
    }, [manageTenantUserDetails])

    const updateRolesButtonStyles = {
        color: tenantTheme?.Color4 || '#FFFFFF',
        backgroundColor: tenantTheme?.Color2 || '#018b8b',
        float: 'right',
        marginBottom: "4px",
        '&:hover': {
            backgroundColor: tenantTheme?.Color1 || '#0d4557',
            color: tenantTheme?.Color5 || '#00ffa9',
        }
    }

    const FetchMangeUserDetails = async () => {
        try {
            const response = await AppOwnsDataWebApi.GetUsersAdmin();
            setTenantUserDetails([...response.users]);
            setTenantsList([...response.tenants]);
            setConfiguredTenantRoles([...response.tenantRoles]);
            setPending(false);
        } catch (error) {
            setPending(false);
        }
    };

    const CheckUserPermissions = async () => {
        const respToProceed = await AppOwnsDataWebApi.IsValidUser(account.username, ["IS_DBIZ_ADMIN"]);
        if (respToProceed === true) {
            setHasPermissions(true);
        } else {
            setHasPermissions(false);
        }
        setIsLoading(false);
    };


    useEffect(() => {
        try {
            if (!isAuthenticated)
                return;

            CheckUserPermissions();
        } catch (e) {
            setIsLoading(false);
            setDisplayMainLoader(false);
        }
    }, [])

    useEffect(() => {
        try {
            if (hasPermissions == false)
                return;

            FetchMangeUserDetails();
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }, [hasPermissions])

    const filterSearch = () => {
        let filteredData = manageTenantUserDetails;
        if (searchedUserName) {
            filteredData = filteredData.filter((user) =>
                user.userName?.toLowerCase().includes(searchedUserName.toLowerCase())
            );
        }

        if (searchedLoginId) {
            filteredData = filteredData.filter((user) =>
                user.loginId?.toLowerCase().includes(searchedLoginId.toLowerCase())
            );
        }

        if (selectedTenant) {
            filteredData = filteredData.filter((user) =>
                user.tenantName === (selectedTenant === 'unassigned' ? "" : selectedTenant)
            );
        }
        setFilteredTenantUserDetails(filteredData);
    }

    const clearFilters = () => {
        setFilteredTenantUserDetails(manageTenantUserDetails);
        setSearchedLoginId('');
        setSearchedUserName('');
        setSelectedTenant('');
        const searchByUsernameInput = document.getElementById('search-username') as HTMLInputElement;
        if (searchByUsernameInput) {
            searchByUsernameInput.value = '';
        }

        const searchByLoginIdInput = document.getElementById('search-loginId') as HTMLInputElement;
        if (searchByLoginIdInput) {
            searchByLoginIdInput.value = '';
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            filterSearch();
        }
    };

    const getUserReportsToManage = async (mangedUserName: string) => {
        try {
            setDisplayMainLoader(true);
            setShowReportsTab(false);
            setCurrentUserForReportAssignment(mangedUserName);
            const response = await AppOwnsDataWebApi.GetUserReports(mangedUserName);
            setManageReports([...response.allReports]);
            setFilteredManageReports([...response.allReports]);
            setAssignedReportIds(response.allReports.map((x) => {
                if (x.isAssigned === true) {
                    return x.reportID
                }
            }).filter(reportId => reportId !== undefined));
            setShowReportsTab(true);
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
            setPending(false);
        }
    }

    const handleRightsAssignment = async (rightType: string, userBeingManaged: string) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.ToggleUserRights(userBeingManaged, rightType);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsersAdmin(),
                    {
                        success: 'User Right Updated',
                        error: 'An Error Occured'
                    }
                );
                setTenantUserDetails([...response.users]);
            }
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    const updateRoleChange = async (loginId: string, reportCodeId: number) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.UpdateUserReportRole(loginId, reportCodeId);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsersAdmin(),
                    {
                        success: 'Role Updated',
                        error: 'An Error Occured'
                    }
                );
                setTenantUserDetails([...response.users]);
            }
            else {
                toast.error("An Unexpected Error Occured!");
            }
            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    const manageTenantUserColumns = [
        {
            name: 'DELETE',
            button: true,
            cell: (row: MangeTenantUsersRowData) => {
                return (
                    <>
                        {
                            <DeleteIcon
                                sx={{
                                    color: (tenantTheme?.Color1 || '#0d4557'),
                                    cursor: 'pointer'
                                }}
                                onClick={() => { handleDeleteClick(row.loginId) }} />
                        }
                    </>
                )
            }
        },
        {
            name: 'LOGIN ID',
            selector: (row: MangeTenantUsersRowData) => row.loginId,
            minWidth: "230px",
        },
        {
            name: 'USERNAME',
            selector: (row: MangeTenantUsersRowData) => row.userName,
            minWidth: "230px",
        },
        {
            name: 'ROLE',
            minWidth: "300px",
            cell: (row: MangeTenantUsersRowData) => (
                <FormControl size="small" variant='standard'>
                    <Stack direction="row" spacing={1}>
                        <Select
                            id={row.loginId}
                            value={row.roleId || 0}
                            onChange={(e) => { updateRoleChange(row.loginId, e.target.value as number) }}
                            sx={{
                                color: 'black',
                                width: '250px',
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                Unassigned
                            </MenuItem>
                            {configuredTenantRoles?.filter(x => x.tenantName == row.tenantName)?.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </FormControl>
            ),
        },
        {
            name: 'TENANT',
            minWidth: "280px",
            cell: (row: MangeTenantUsersRowData) => {
                const [editMode, setEditMode] = useState(false);
                const [selectedValue, setSelectedValue] = useState(row.tenantName ? row.tenantName : "Unassigned");

                const handleEditClick = () => {
                    setEditMode(true);
                };

                const handleSaveClick = async (userId: string) => {
                    setDisplayMainLoader(true);
                    const response = await AppOwnsDataWebApi.UpdateUserTenant(userId, selectedValue);
                    if (response === true) {
                        const response = await toast.promise(
                            AppOwnsDataWebApi.GetUsersAdmin(),
                            {
                                success: 'User Tenant Updated',
                                error: 'An Error Occured'
                            }
                        );
                        setTenantUserDetails([...response.users]);
                        //setFilteredTenantUserDetails([...response.users]);
                    }
                    setDisplayMainLoader(false);
                    setEditMode(false);
                };

                const handleCancelClick = () => {
                    setSelectedValue(row.tenantName ? row.tenantName : "Unassigned");
                    setEditMode(false);
                };

                return (
                    <FormControl size="small" variant='standard'>
                        {
                            editMode ? (
                                <>
                                    <Stack direction="row" spacing={1}>
                                        <Select
                                            labelId={`tenantName-label`}
                                            id={`tenantName-select`}
                                            value={selectedValue}
                                            onChange={(event) => setSelectedValue(event.target.value)}
                                            label="Tenant"
                                            sx={{
                                                color: 'black',
                                                width: '200px'
                                            }}
                                        >
                                            <MenuItem value="Unassigned">[Unassigned]</MenuItem>
                                            {tenantsList.map((tenant) => {
                                                return (
                                                    <MenuItem key={tenant} value={tenant}>
                                                        {tenant}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <IconButton onClick={() => handleSaveClick(row.loginId)}>
                                            <SaveIcon sx={{ color: (tenantTheme?.Color1 || '#0d4557') }} />
                                        </IconButton>
                                        <IconButton onClick={handleCancelClick}>
                                            <CancelIcon sx={{ color: (tenantTheme?.Color2 || '#018b8b') }} />
                                        </IconButton>
                                    </Stack>
                                </>
                            ) : (
                                <Input
                                    readOnly
                                    value={selectedValue}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleEditClick}>
                                                <EditIcon sx={{ color: (tenantTheme?.Color1 || '#0d4557') }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            )}
                    </FormControl>
                )
            },
        },
        {
            name: 'REPORT LANGUAGE',
            minWidth: "300px",
            cell: (row: MangeTenantUsersRowData) => (
                <FormControl size="small" variant='standard'>
                    <Stack direction="row" spacing={1}>
                        <Select
                            id={row.lastLogin}
                            value={row.reportLanguageCode}
                            onChange={(e) => { UpdateReportLanguageCode(row.loginId, e.target.value) }}
                            sx={{
                                color: 'black',
                                width: '250px',
                            }}
                        >
                            {languages.map((language) => (
                                <MenuItem key={language.code} value={language.code}>
                                    {language.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </FormControl >
            ),
        },
        {
            name: 'CAN EDIT',
            cell: (row: MangeTenantUsersRowData) => (
                <Checkbox checked={row.canEdit} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("edit", row.loginId); }} />
            ),
        },
        {
            name: 'CAN CREATE',
            cell: (row: MangeTenantUsersRowData) => (
                <Checkbox checked={row.canCreate} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("create", row.loginId); }} />
            ),
        },
        {
            name: 'SUPER ADMIN',
            cell: (row: MangeTenantUsersRowData) => (
                <Checkbox checked={row.isSuperUser} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("super", row.loginId); }} />
            ),
        },
        {
            name: 'DBIZ ADMIN',
            cell: (row: MangeTenantUsersRowData) => (
                <Checkbox checked={row.isDbizAdmin} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("dbiz", row.loginId); }} />
            ),
        },
        {
            name: 'REPORTS',
            button: true,
            cell: (row: MangeTenantUsersRowData) => (
                <Button
                    variant="contained"
                    sx={{
                        color: tenantTheme?.Color4 || '#FFFFFF',
                        backgroundColor: tenantTheme?.Color1 || '#0d4557',
                        '&:hover': {
                            backgroundColor: tenantTheme?.Color2 || '#018b8b',
                        },
                    }}
                    onClick={() => { getUserReportsToManage(row.loginId) }}
                >Assign</Button >
            )
        }
    ];

    if (!isAuthenticated) {
        return <Unauthenticated />;
    }

    if (isLoading === true) {
        return null;
    }

    if (isLoading === false && !hasPermissions) {
        return <Unauthorized />;
    }

    if (isAuthenticated && hasPermissions) {
        return (
            <>
                <Container maxWidth={false} sx={mainContainerStyles}>
                    {showReportsTab === false && (
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                            >
                                <Typography variant='h5' component="h2" sx={pageTitleStyles} >DBIZ MANAGEMENT</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        {pending === false && (
                                            <>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                                    <IconButton sx={{
                                                        color: tenantTheme?.Color1 || '#0d4557',
                                                    }} onClick={() => {
                                                        navigate("/reports/" + embeddingData?.pageLoadReportId);
                                                    }}>
                                                        <ArrowBackIcon />
                                                    </IconButton>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                        Back to Reports
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                        <DataTable
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        color: tenantTheme?.Color3 || '#FFFFFF',
                                                        backgroundColor: tenantTheme?.Color1 || '#0d4557'
                                                    },
                                                },
                                            }}
                                            columns={manageTenantUserColumns}
                                            data={manageFilteredTenantUserDetails}
                                            keyField={"loginId"}
                                            pagination
                                            progressPending={pending}
                                            progressComponent={<CustomLoader />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={mainContainerGridStyles}>
                                        <Container sx={container1Styles}>
                                            <Box sx={box1Styles}>
                                                <FilterAltIcon sx={filterIconStyles} />
                                                <Typography variant="h6" sx={applyFilterTextStyles}>APPLY FILTERS</Typography>
                                            </Box>
                                            <Container
                                                sx={container2Styles}>
                                                <Box sx={box1Styles}>
                                                    <HomeWorkIcon sx={filtersIconStyles} />
                                                    <Typography variant="h6" sx={filtersTypoGraphyStyles}>
                                                        Tenants : {tenantsList.length}
                                                    </Typography>
                                                </Box>
                                            </Container>
                                            <Container
                                                sx={container2Styles}>
                                                <Box sx={box1Styles}>
                                                    <PeopleOutlineIcon sx={filtersIconStyles} />
                                                    <Typography variant="h6" sx={filtersTypoGraphyStyles}>
                                                        Users Unassigned: {manageFilteredTenantUserDetails.filter(user => user.tenantName === '' || user.tenantName === null).length}
                                                    </Typography>
                                                </Box>
                                            </Container>
                                            <Container
                                                sx={container2Styles}>
                                                <Box sx={box2Styles}>
                                                    <Input
                                                        id="search-username"
                                                        sx={searchInputs1Styles}
                                                        onKeyPress={handleKeyPress}
                                                        placeholder="Search By User Name"
                                                        inputProps={{ 'aria-label': 'Search By User Name' }}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setSearchedUserName(e.target.value); }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton type="button" aria-label="search">
                                                                    <SearchIcon sx={iconStyles} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Box>
                                                <Box sx={box2Styles}>
                                                    <Input
                                                        id="search-loginId"
                                                        sx={searchInputs1Styles}
                                                        onKeyPress={handleKeyPress}
                                                        placeholder="Search By Login ID"
                                                        inputProps={{ 'aria-label': 'Search By Login ID' }}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setSearchedLoginId(e.target.value); }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton type="button" aria-label="search">
                                                                    <SearchIcon sx={iconStyles} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Box>
                                                <Box sx={box3Styles}>
                                                    <Select
                                                        labelId="tenantSelect-label"
                                                        id="tenantSelect"
                                                        value={selectedTenant}
                                                        onChange={(e) => { setSelectedTenant(e.target.value); }}
                                                        input={<Input />}
                                                        sx={searchInputs2Styles}
                                                    >
                                                        <MenuItem value="unassigned">Unassigned</MenuItem>
                                                        {tenantsList.map((tenant) => (
                                                            <MenuItem key={tenant} value={tenant}>
                                                                {tenant}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </Container>
                                            <Box sx={box4Styles}>
                                                <Button
                                                    onClick={filterSearch}
                                                    sx={applyFiltersButtonStyles}>
                                                    Apply Filters
                                                </Button>
                                                <Button
                                                    onClick={clearFilters}
                                                    sx={clearFiltersButtonStyles}>
                                                    Clear Filters
                                                </Button>
                                                <Button
                                                    onClick={handleNewUserCreationClickOpen}
                                                    sx={addNewUserButtonStyles}>
                                                    Add New User
                                                </Button>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </AnimatePresence>
                    )}
                    {
                        showReportsTab === true && (
                            <ReportAssignment
                                currentUserForReportAssignment={currentUserForReportAssignment}
                                setShowReportsTab={setShowReportsTab}
                                manageReports={manageReports}
                                manageFilteredReports={manageFilteredReports}
                                assignedReportIds={assignedReportIds}
                                setManageReports={setManageReports}
                                setFilteredManageReports={setFilteredManageReports}
                                setAssignedReportIds={setAssignedReportIds}
                                callingScreen={"DBIZManagement"}
                            />
                        )
                    }
                    <UserCreation
                        userCreationModalOpen={userCreationModalOpen}
                        setUserCreationModalOpen={setUserCreationModalOpen}
                        setEmail={setEmail}
                        email={email}
                        setUserName={setUserName}
                        userName={userName}
                        setShowValidationMessage={setShowValidationMessage}
                        showValidationMessage={showValidationMessage}
                        setMangeUserDetails={null}
                        setTenantUserDetails={setTenantUserDetails}
                        loggedInUser={account.username}
                        callingScreen={"DBIZManagement"}
                    />

                    {/*User Deleteion Modal*/}
                    {isDeleteUserModalOpen && (
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                            >
                                <Dialog
                                    open={isDeleteUserModalOpen}
                                    onClose={handleCloseDeleteModal}>
                                    <DialogTitle>CONFIRM DELETION</DialogTitle>
                                    <DialogContent>
                                        Are you sure you want to delete user {selectedUserToDelete}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                color: tenantTheme?.Color4 || '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                },
                                            }}
                                            onClick={handleCloseDeleteModal}>Cancel</Button>
                                        <Button
                                            sx={{
                                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                                color: tenantTheme?.Color3 || '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                                },
                                            }}
                                            onClick={handleConfirmDelete}>Delete</Button>
                                    </DialogActions>
                                </Dialog>
                            </motion.div>
                        </AnimatePresence>
                    )}
                </Container>
            </>
        )
    }
};

export default DBIZManagement;