import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Checkbox, Container, IconButton, Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, default as React, Dispatch, SetStateAction, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AppContext } from '../AppContext';
import { MangeReportsRowData, Reports } from '../models/models';
import AppOwnsDataWebApi from '../services/AppOwnsDataWebApi';

interface ReportAssignmentProps {
    currentUserForReportAssignment: string;
    setShowReportsTab: Dispatch<SetStateAction<boolean>>;
    manageReports: Reports[];
    manageFilteredReports: any[];
    assignedReportIds: string[];
    setManageReports: Dispatch<SetStateAction<any[]>>;
    setFilteredManageReports: Dispatch<SetStateAction<any[]>>;
    setAssignedReportIds: Dispatch<SetStateAction<string[]>>;
    callingScreen: string;
}

const ReportAssignment = ({
    currentUserForReportAssignment,
    setShowReportsTab,
    manageReports,
    manageFilteredReports,
    assignedReportIds,
    setManageReports,
    setFilteredManageReports,
    setAssignedReportIds,
    callingScreen
}: ReportAssignmentProps) => {
    const { displayMainLoader, setDisplayMainLoader, tenantTheme } = useContext(AppContext);
    const [locallyAssignedReports, setLocallyAssignedReports] = React.useState([]);

    const reportsColumn = [
        {
            name: 'SELECT',
            cell: (row: MangeReportsRowData) => (
                <Checkbox checked={row.isAssigned} style={{ color: '#0d4557' }} onClick={() => { handleReportAssignmentChange(row.reportID) }} />
            ),
        },
        {
            name: 'REPORT ID',
            cell: (row: MangeReportsRowData) => row.reportID,
            omit: true
        },
        {
            name: 'REPORT NAME',
            selector: (row: MangeReportsRowData) => row.reportName,
            sortable: true,
        },
        {
            name: 'TENANT NAME',
            selector: (row: MangeReportsRowData) => row.workspaceName,
            sortable: true,
        },
        {
            name: (
                <Tooltip title="Click On The Report You Would Like To Set As The Default Landing Report.">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        DEFAULT REPORT <InfoIcon fontSize="small" style={{ marginLeft: '4px', marginBottom: '2px' }} />
                    </span>
                </Tooltip>
            ),
            cell: (row: MangeReportsRowData) => {
                return (
                    <IconButton
                        color="primary"
                        onClick={() => {
                            handleDefaultReportChange(row.reportID)
                        }}>
                        {row.isDefaultReport ?
                            <BookmarkAddedIcon sx={{ color: '#30dea3' }} />
                            : <BookmarkAddOutlinedIcon sx={{ color: 'black' }} />}
                    </IconButton>
                );
            },
        },
    ];

    const handleReportAssignment = async (assignAllReport: boolean) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.UpdateUserReports(currentUserForReportAssignment, assignAllReport === true ? manageFilteredReports.map(x => x.reportID) : assignedReportIds);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUserReports(currentUserForReportAssignment),
                    {
                        success: 'Reports Assigned',
                        error: 'An Error Occured'
                    }
                );
                setLocallyAssignedReports([]);
                setManageReports([...response.allReports]);
                setFilteredManageReports([...response.allReports]);
                setShowReportsTab(true);
            }
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    const handleReportAssignmentChange = (reportId: string) => {

        if (locallyAssignedReports.includes(reportId))
            setLocallyAssignedReports(locallyAssignedReports.filter(x => x !== reportId))
        else
            setLocallyAssignedReports([...locallyAssignedReports, reportId]);

        setManageReports(prevReports => {
            return prevReports.map(report => {
                if (report.reportID === reportId) {
                    return {
                        ...report,
                        isAssigned: !report.isAssigned
                    };
                }
                return report;
            });
        });

        setFilteredManageReports(prevReports => {
            return prevReports.map(report => {
                if (report.reportID === reportId) {
                    return {
                        ...report,
                        isAssigned: !report.isAssigned
                    };
                }
                return report;
            });
        });

        if (assignedReportIds.indexOf(reportId) > -1)
            setAssignedReportIds(assignedReportIds.filter(x => x !== reportId))
        else
            setAssignedReportIds([...assignedReportIds, reportId]);

    }

    const filterReports = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.value === "") {
            setFilteredManageReports(manageReports);
        }
        else {
            const filteredReportNames = manageReports?.map((x) => {
                if (x.reportName?.toLowerCase().includes(event?.target?.value?.toLowerCase())) {
                    return x;
                }
            })?.filter((x) => x !== undefined);
            setFilteredManageReports(filteredReportNames);
        }
    }

    const handleDefaultReportChange = async (reportId: string) => {
        try {
            setDisplayMainLoader(true);

            if (locallyAssignedReports.includes(reportId)) {
                toast.warning("Only Saved Reports can be set as Default.");
                setDisplayMainLoader(false);
                return;
            }

            if (!assignedReportIds.includes(reportId)) {
                toast.warning("Only Saved Reports can be set as Default.");
                setDisplayMainLoader(false);
                return;
            }

            const response = await AppOwnsDataWebApi.UpdateUserDefaultReport(currentUserForReportAssignment, reportId);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUserReports(currentUserForReportAssignment),
                    {
                        success: 'Default Report Set',
                        error: 'An Error Occured'
                    }
                );
                setManageReports([...response.allReports]);
                setFilteredManageReports([...response.allReports]);
                setShowReportsTab(true);
            }
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                <Container maxWidth="xl">
                    <Typography variant='h5' component="h2" sx={{ mt: 2, mb: "6px", textAlign: 'center', fontWeight: '500px' }} >ASSIGN REPORTS</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <IconButton sx={{
                            color: tenantTheme?.Color1 || '#0d4557',
                        }} onClick={() => {
                            setLocallyAssignedReports([]);
                            setShowReportsTab(false)
                        }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Back to {callingScreen === "UserManagement" ? "User Management" : "DBIZ Management"}
                        </Typography>
                    </Box>
                    <Paper
                        component="form"
                        elevation={4}
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            width: 400,
                            float: 'right',
                            height: '36px',
                            marginBottom: '10px'
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Report Name"
                            inputProps={{ 'aria-label': 'Search Report Name' }}
                            onChange={filterReports}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px', color: (tenantTheme?.Color1 || '#0d4557') }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <DataTable
                        customStyles={{
                            headCells: {
                                style: {
                                    color: tenantTheme?.Color3 || '#FFFFFF',
                                    backgroundColor: tenantTheme?.Color1 || '#0d4557'
                                },
                            },
                        }}
                        columns={reportsColumn}
                        data={manageFilteredReports}
                        pagination
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                marginRight: '10px',
                                color: tenantTheme?.Color4 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color2 || '#018b8b'
                                },
                            }}
                            disabled={displayMainLoader}
                            onClick={() => {
                                handleReportAssignment(true);
                            }}
                        >
                            Assign All Reports
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                color: tenantTheme?.Color3 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color1 || '#0d4557'
                                },
                            }}
                            disabled={displayMainLoader}
                            onClick={() => {
                                handleReportAssignment(false);
                            }}
                        >
                            Assign Selected Reports
                        </Button>
                    </Box>
                </Container>
            </motion.div>
        </AnimatePresence>
    );

}

export default ReportAssignment;