import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import DataLoading from '../DataLoading';
import PageNotAccessible from './../PageNotAccessible';

const titleStyles = {
    mt: 2,
    mb: "6px"
}

const tableContainerStyles = {
    backgroundColor: "white",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
}

const tableCells1Styles = {
    width: "200px"
}

const tableStyles = {
    minWidth: 650
}

const Profile = () => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { embeddingData, isSuperUser } = useContext(AppContext);

    if (!isAuthenticated) {
        return <PageNotAccessible />;
    }

    if (embeddingData.workspaceArtifactsLoading) {
        return <DataLoading />
    }
    else {
        return (
            <Container maxWidth={false}>
                <Container maxWidth="xl">
                    <Typography variant='h6' component="h4" sx={titleStyles}>Login Session Info:</Typography>
                    <TableContainer component={Paper} sx={tableContainerStyles}>
                        <Table sx={tableStyles} aria-label="simple table" size="small" >
                            <TableBody>
                                <TableRow key={"username"} >
                                    <TableCell scope="row" sx={tableCells1Styles} >
                                        User Login:
                                    </TableCell>
                                    <TableCell><strong>{account?.username}</strong></TableCell>
                                </TableRow>
                                <TableRow key={"name"} >
                                    <TableCell scope="row" sx={tableCells1Styles} >
                                        User Display Name:
                                    </TableCell>
                                    <TableCell><strong>{account?.name}</strong></TableCell>
                                </TableRow>
                                <TableRow key={"tenant"} >
                                    <TableCell scope="row" sx={tableCells1Styles} >
                                        Tenant Name:
                                    </TableCell>
                                    <TableCell><strong>{embeddingData.tenantName}</strong></TableCell>
                                </TableRow>
                                <TableRow key={"userCanEdit"} >
                                    <TableCell scope="row">
                                        User can edit content:
                                    </TableCell>
                                    <TableCell><strong>{String(embeddingData.userCanEdit)}</strong></TableCell>
                                </TableRow>
                                <TableRow key={"userCanCreate"} >
                                    <TableCell scope="row">
                                        User can create content:
                                    </TableCell>
                                    <TableCell><strong>{String(embeddingData.userCanCreate)}</strong></TableCell>
                                </TableRow>
                                <TableRow key={"userIsSuperUser"} >
                                    <TableCell scope="row">
                                        User Is super user:
                                    </TableCell>
                                    <TableCell><strong>{String(isSuperUser)}</strong></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Container >
        )
    }
}

export default Profile;