import { useAccount, useMsal } from "@azure/msal-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Container, IconButton, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../AppContext';
import { Dropdowns, TenantIndicators, Tickets } from "../models/models";
import AppOwnsDataWebApi from '../services/AppOwnsDataWebApi';

interface TicketViewProps {
    currentRequestId: number;
    setRequestCreationModalOpen: Dispatch<SetStateAction<boolean>>;
    currentRequestDetails: Tickets;
    dropDownDetails: Dropdowns[];
    fetchData: () => Promise<void>;
    allowUpdation: () => boolean;
    screenType: string;
    tenantUsers?: string[];
    tenantAndDbizUsers?: string[];
    selectedTenant?: string;
    tenantsDropdown?: TenantIndicators[];
    setSelectedTenant?: Dispatch<SetStateAction<string>>;
}

const TicketView = ({
    currentRequestId,
    setRequestCreationModalOpen,
    currentRequestDetails,
    dropDownDetails,
    fetchData,
    allowUpdation,
    screenType,
    tenantUsers,
    tenantAndDbizUsers,
    selectedTenant,
    tenantsDropdown,
    setSelectedTenant
}: TicketViewProps) => {
    const { setDisplayMainLoader, tenantTheme } = useContext(AppContext);

    const [title, setTitle] = useState(currentRequestDetails?.title || '');
    const [description, setDescription] = useState(currentRequestDetails?.description || '');
    const [reportPageReference, setReportPageReference] = useState(currentRequestDetails?.reportPageReference || '');
    const [reportReference, setReportReference] = useState(currentRequestDetails?.reportReference || '');
    const [statusNoteCustomer, setStatusNoteCustomer] = useState(currentRequestDetails?.statusNoteCustomer || '');
    const [priorityId, setPriorityId] = useState(currentRequestDetails?.priorityId || 0);
    const [requestCostId, setRequestCostId] = useState(currentRequestDetails?.requestCostId || 0);
    const [requestTypeId, setRequestTypeId] = useState(currentRequestDetails?.requestTypeId || 0);

    const [statusId, setStatusId] = useState(currentRequestDetails?.statusId || 0);
    const [assignedToUserId, setAssignedToUserId] = useState(currentRequestDetails?.assignedToId || '');
    const [customerApproverUserId, setCustomerApproverUserId] = useState(currentRequestDetails?.customerApproverId || '');
    const [completedByUserId, setCompletedByUserId] = useState(currentRequestDetails?.completedById || '');

    const [approvedDate, setApprovedDate] = useState<string | null>(currentRequestDetails?.approvalDate || null);
    const [plannedDueDate, setPlannedDueDate] = useState<string | null>(currentRequestDetails?.plannedDueDateDBIZ || null);
    const [completedDate, setCompletedDate] = useState<string | null>(currentRequestDetails?.completedDate || null);

    const [hourEstimatesDBIZ, setHourEstimatesDBIZ] = useState(currentRequestDetails?.hoursEstimatesDBIZ || 0);
    const [noteDBIZInternal, setNotesDBIZInternal] = useState(currentRequestDetails?.notesInternalDBIZ || '');
    const [statusNoteDBIZ, setStatusNoteDBIZ] = useState(currentRequestDetails?.statusNoteDBIZ || '');

    const [currentTenant, setCurrentTenant] = useState('');

    const [files, setFiles] = useState<File[]>([]);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = () => {
        if (fileInputRef.current?.files) {
            const selectedFiles = fileInputRef.current.files;

            const filesArray: File[] = Array.from(selectedFiles);

            // Filter files that are below 10MB
            const validFiles = filesArray.filter((file) => file.size <= 10 * 1024 * 1024);

            if (validFiles.length !== selectedFiles.length) {
                toast.warning("Some file(s) exceeding 10MB have been excluded. Please ensure that each selected file adheres to the 10MB limit.");
            }

            setFiles((prevFiles) => [...prevFiles, ...validFiles]);

            // Clear the value of the file input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

        }
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const handleButtonClick = () => {
        // Triggerring the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileDownload = async (attachmentId: number, fileName: string) => {
        try {
            setDisplayMainLoader(true);
            await AppOwnsDataWebApi.DownloadFile(attachmentId, fileName);
            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
            toast.error("An Error Occured while Downloading the File!");
            console.error(e);
        }
    }

    useEffect(() => {
        if (currentRequestId === 0) {
            clearInputs();
        }
        else {
            setTitle(currentRequestDetails?.title || '');
            setDescription(currentRequestDetails?.description || '');
            setReportPageReference(currentRequestDetails?.reportPageReference || '');
            setReportReference(currentRequestDetails?.reportReference || '');
            setStatusNoteCustomer(currentRequestDetails?.statusNoteCustomer || '');
            setPriorityId(currentRequestDetails?.priorityId || 0);
            setRequestTypeId(currentRequestDetails?.requestTypeId || 0);
            setRequestCostId(currentRequestDetails?.requestCostId || 0);
            setFiles([]);
            setAssignedToUserId(currentRequestDetails?.assignedToId || '');
            setCustomerApproverUserId(currentRequestDetails?.customerApproverId || '');
            setCompletedByUserId(currentRequestDetails?.completedById || '');
            setApprovedDate(currentRequestDetails?.approvalDate || null);
            setPlannedDueDate(currentRequestDetails?.plannedDueDateDBIZ || null);
            setCompletedDate(currentRequestDetails?.completedDate || null);
            setHourEstimatesDBIZ(currentRequestDetails?.hoursEstimatesDBIZ || 0);
            setNotesDBIZInternal(currentRequestDetails?.notesInternalDBIZ || '');
            setStatusNoteDBIZ(currentRequestDetails?.statusNoteDBIZ || '');

        }
    }, [currentRequestId]);

    const clearInputs = () => {
        setTitle('');
        setDescription('');
        setReportPageReference('');
        setReportReference('');
        setStatusNoteCustomer('');
        setPriorityId(0);
        setRequestTypeId(0);
        setRequestCostId(0);
        setFiles([]);
        setStatusId(0);
        setAssignedToUserId('');
        setCompletedByUserId('');
        setCustomerApproverUserId('');
        setApprovedDate('');
        setPlannedDueDate('');
        setCompletedDate('');
        setHourEstimatesDBIZ(0);
        setNotesDBIZInternal('');
        setStatusNoteDBIZ('');
    };

    const handleCreate = async () => {
        if (currentRequestId === 0) {
            try {
                if (screenType === "DbizTicketManagementUi") {
                    if (currentTenant === '' || !title || !description || priorityId === 0 || requestTypeId === 0) {
                        toast.warning("Please make sure all the required fields are entered correctly");
                        return;
                    }
                }
                else {
                    if (!title || !description || priorityId === 0 || requestTypeId === 0) {
                        toast.warning("Please make sure all the required fields are entered correctly");
                        return;
                    }
                }

                setDisplayMainLoader(true);
                var sampleObj = {};
                if (screenType !== "DbizTicketManagementUi") {
                    sampleObj = {
                        title: title.trim(),
                        description: description.trim(),
                        priorityId,
                        requestTypeId,
                        reportReference: reportReference.trim(),
                        reportPageReference: reportPageReference.trim(),
                        statusNoteCustomer: statusNoteCustomer.trim(),
                        statusNoteDBIZ: statusNoteDBIZ.trim(),
                        notesInternalDBIZ: noteDBIZInternal.trim(),
                        hourEstimatesDBIZ,
                        requestCostId,
                        assignedToUserId,
                        customerApproverUserId,
                        completedByUserId,
                        statusId,
                        plannedDueDate,
                        completedDate,
                        screenType,
                        selectedTenant
                    };

                } else {
                    sampleObj = {
                        title: title.trim(),
                        description: description.trim(),
                        priorityId,
                        requestTypeId,
                        reportReference: reportReference.trim(),
                        reportPageReference: reportPageReference.trim(),
                        statusNoteCustomer: statusNoteCustomer.trim(),
                        statusNoteDBIZ: statusNoteDBIZ.trim(),
                        notesInternalDBIZ: noteDBIZInternal.trim(),
                        hourEstimatesDBIZ,
                        requestCostId,
                        assignedToUserId,
                        customerApproverUserId,
                        completedByUserId,
                        statusId,
                        plannedDueDate,
                        completedDate,
                        screenType,
                        selectedTenant: currentTenant
                    };
                }

                const creationResp = await AppOwnsDataWebApi.UpsertTicket(account.username, sampleObj);
                if (creationResp && creationResp > 0) {
                    let showWarning = false;
                    if (files.length > 0) {
                        const addAttachResponse = await AppOwnsDataWebApi.AddAttachments(files, creationResp);
                        if (addAttachResponse === false) {
                            showWarning = true;
                        }
                    }
                    await fetchData();

                    if (showWarning === true) {
                        toast.warning("The request was created, but there was an issue uploading attachments. Please try adding the attachments to the request again.");
                    } else {
                        toast.success('New Request Created');
                    }
                }
                clearInputs();
                setRequestCreationModalOpen(false);
                setDisplayMainLoader(false);
            } catch (error) {
                toast.error("An Unexpected Error Occured")
                console.error(error);
                setDisplayMainLoader(false);
            }
        }
    }

    const handleUpdate = async () => {
        if (currentRequestId !== 0) {
            try {
                if (!title || !description || priorityId === 0 || requestTypeId === 0) {
                    toast.warning("Please make sure all the required fields are entered correctly");
                    return;
                }

                setDisplayMainLoader(true);

                const sampleObj = {
                    ticketID: currentRequestId,
                    title: title.trim(),
                    description: description.trim(),
                    priorityId,
                    requestTypeId,
                    reportReference: reportReference.trim(),
                    reportPageReference: reportPageReference.trim(),
                    statusNoteCustomer: statusNoteCustomer.trim(),
                    statusNoteDBIZ: statusNoteDBIZ.trim(),
                    notesInternalDBIZ: noteDBIZInternal.trim(),
                    hourEstimatesDBIZ,
                    requestCostId,
                    assignedToUserId,
                    customerApproverUserId,
                    completedByUserId,
                    statusId,
                    plannedDueDate,
                    completedDate,
                    screenType,
                    selectedTenant
                };

                const creationResp = await AppOwnsDataWebApi.UpsertTicket(account.username, sampleObj);
                if (creationResp && creationResp > 0) {
                    let showWarning = false;
                    if (files.length > 0) {
                        const addAttachResponse = await AppOwnsDataWebApi.AddAttachments(files, creationResp);
                        if (addAttachResponse === false) {
                            showWarning = true;
                        }
                    }

                    clearInputs();
                    if (showWarning === true) {
                        toast.warning("The request was updated, but there was an issue uploading new attachments. Please try adding the attachments to the request again.");
                    } else {
                        toast.success('Request Updated');
                    }
                }

                setRequestCreationModalOpen(false);
                await fetchData();
            } catch (error) {
                toast.error("An Error Occurred while creating a Request");
                console.error(error);
                setDisplayMainLoader(false);
            }
        }
    }

    const container2Styles = {
        backgroundColor: 'white',
        borderRadius: '6px',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
        marginBottom: '15px',
        borderLeft: '4px solid ' + (tenantTheme?.Color5 || '#00ffa9'),
        height: '70%',
        overflowY: 'auto',
    }

    const searchInputs2Styles = {
        color: '#0d4557',
        width: '100%',
        marginTop: "10px",
        marginBottom: "10px",
        cursor: 'pointer'
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <IconButton sx={{
                    color: tenantTheme?.Color1 || '#0d4557',
                }} onClick={() => {
                    setRequestCreationModalOpen(false);
                }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Back
                </Typography>
            </Box>
            <Container
                sx={container2Styles}>
                {currentRequestId === 0 && screenType === "DbizTicketManagementUi" &&
                    <div style={{ marginTop: '10px' }}
                    >
                        <InputLabel id="tenantsDropdown-label">Select Tenant *</InputLabel>
                        <Select
                            labelId="tenantsDropdown-label"
                            id="tenantsDropdown"
                            value={currentTenant || null}
                            label="Tenants"
                            input={<Input />}
                            sx={searchInputs2Styles}
                            onChange={(e) => {
                                setCurrentTenant(e.target.value);
                                setSelectedTenant(e.target.value as string);
                            }}
                        >
                            {tenantsDropdown
                                ?.map((filteredDropdown: TenantIndicators) => (
                                    <MenuItem key={filteredDropdown.name} value={filteredDropdown.name}>
                                        {filteredDropdown.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                }
                {currentRequestId !== 0 && screenType === "DbizTicketManagementUi" &&
                    <TextField
                        fullWidth
                        disabled
                        label="Tenant"
                        variant="standard"
                        value={selectedTenant}
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            cursor: 'pointer'
                        }}
                    />
                }
                <TextField
                    fullWidth
                    label="Title *"
                    variant="standard"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: 'pointer'
                    }}
                />
                <TextField
                    fullWidth
                    id="description-textarea"
                    label="Description *"
                    placeholder="Please Enter Text Description"
                    multiline
                    variant="standard"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: 'pointer'
                    }}
                />
                <InputLabel id="priority-label">Priority *</InputLabel>
                <Select
                    labelId="priority-label"
                    label="Priority"
                    id="priority"
                    value={priorityId}
                    input={<Input />}
                    sx={searchInputs2Styles}
                    onChange={(e) => { setPriorityId(e.target.value as number) }}
                >
                    {dropDownDetails
                        ?.filter((dropdown: Dropdowns) => dropdown.context === "PRIORITY TYPES")
                        .map((filteredDropdown: Dropdowns) => (
                            <MenuItem key={filteredDropdown.statusID} value={filteredDropdown.statusID}>
                                {filteredDropdown.statusName}
                            </MenuItem>
                        ))}
                </Select>
                <InputLabel id="requestType-label">Request Type *</InputLabel>
                <Select
                    labelId="requestType-label"
                    id="requestType"
                    label="Request Type"
                    value={requestTypeId}
                    input={<Input />}
                    sx={searchInputs2Styles}
                    onChange={(e) => { setRequestTypeId(e.target.value as number) }}
                >
                    {dropDownDetails
                        ?.filter((dropdown: Dropdowns) => dropdown.context === "SUPPORT REQUEST TYPES")
                        .map((filteredDropdown: Dropdowns) => (
                            <MenuItem key={filteredDropdown.statusID} value={filteredDropdown.statusID}>
                                {filteredDropdown.statusName}
                            </MenuItem>
                        ))}
                </Select>
                <TextField
                    fullWidth
                    id="reportReference-textarea"
                    label="Report Reference"
                    placeholder="Please Enter Report Referece"
                    multiline
                    variant="standard"
                    value={reportReference}
                    onChange={(e) => setReportReference(e.target.value)}
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: 'pointer'
                    }}
                />
                <TextField
                    fullWidth
                    id="reportPageReference-textarea"
                    label="Report Page"
                    placeholder="Please Enter Report Page"
                    multiline
                    variant="standard"
                    value={reportPageReference}
                    onChange={(e) => setReportPageReference(e.target.value)}
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: 'pointer'
                    }}
                />
                <TextField
                    fullWidth
                    id="statusNote-textarea"
                    label="Status Note (Customer)"
                    placeholder="Please Enter Status Note (Customer)"
                    multiline
                    variant="standard"
                    value={statusNoteCustomer}
                    onChange={(e) => setStatusNoteCustomer(e.target.value)}
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: 'pointer'
                    }}
                />

                {screenType === "DbizTicketManagementUi" && (
                    <>
                        <TextField
                            fullWidth
                            id="statusNoteDbiz-textarea"
                            label="Status Note (DBIZ)"
                            placeholder="Please Enter Status Note (DBIZ)"
                            multiline
                            value={statusNoteDBIZ}
                            variant="standard"
                            onChange={(e) => setStatusNoteDBIZ(e.target.value)}
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                cursor: 'pointer'
                            }}
                        />
                        <TextField
                            fullWidth
                            id="NoteInternalDbiz-textarea"
                            label="Notes Internal (DBIZ)"
                            placeholder="Please Enter Internal Notes (DBIZ)"
                            multiline
                            value={noteDBIZInternal}
                            variant="standard"
                            onChange={(e) => setNotesDBIZInternal(e.target.value)}
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                cursor: 'pointer'
                            }}
                        />
                        <TextField
                            fullWidth
                            id="hourEstimateDbiz-textarea"
                            label="Hour Estimate (DBIZ)"
                            placeholder="Please Enter Hour Estimates (DBIZ)"
                            variant="standard"
                            type="number"
                            value={hourEstimatesDBIZ}
                            onChange={(e) => setHourEstimatesDBIZ(Number(e.target.value) as number)}
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                cursor: 'pointer'
                            }}
                        />
                        <InputLabel id="requestCost-label">Request Cost (DBIZ)</InputLabel>
                        <Select
                            labelId="requestCost-label"
                            label="Request Cost"
                            id="requestCost"
                            value={requestCostId}
                            input={<Input />}
                            sx={searchInputs2Styles}
                            onChange={(e) => { setRequestCostId(e.target.value as number) }}
                        >
                            {dropDownDetails
                                ?.filter((dropdown: Dropdowns) => dropdown.context === "REQUEST COST")
                                .map((filteredDropdown: Dropdowns) => (
                                    <MenuItem key={filteredDropdown.statusID} value={filteredDropdown.statusID}>
                                        {filteredDropdown.statusName}
                                    </MenuItem>
                                ))}
                        </Select>
                        <InputLabel id="assignedTo-label">Assigned To</InputLabel>
                        <Select
                            labelId="assignedTo-label"
                            label="Assigned To"
                            id="assignedTo"
                            value={assignedToUserId}
                            input={<Input />}
                            sx={searchInputs2Styles}
                            onChange={(e) => { setAssignedToUserId(e.target.value as string) }}
                        >
                            {tenantAndDbizUsers
                                .map((userEmails: string) => (
                                    <MenuItem key={userEmails} value={userEmails}>
                                        {userEmails}
                                    </MenuItem>
                                ))}
                        </Select>
                        <InputLabel id="customerApprover-label">Customer Approver</InputLabel>
                        <Select
                            labelId="customerApprover-label"
                            label="Customer Approver"
                            id="customerApprover"
                            value={customerApproverUserId}
                            input={<Input />}
                            sx={searchInputs2Styles}
                            onChange={(e) => { setCustomerApproverUserId(e.target.value as string) }}
                        >
                            {tenantUsers
                                .map((userEmails: string) => (
                                    <MenuItem key={userEmails} value={userEmails}>
                                        {userEmails}
                                    </MenuItem>
                                ))}
                        </Select>
                        <InputLabel id="completedBy-label">Completed By</InputLabel>
                        <Select
                            labelId="completedBy-label"
                            label="Completed By"
                            id="completedBy"
                            value={completedByUserId}
                            input={<Input />}
                            sx={searchInputs2Styles}
                            onChange={(e) => { setCompletedByUserId(e.target.value as string) }}
                        >
                            {tenantAndDbizUsers
                                .map((userEmails: string) => (
                                    <MenuItem key={userEmails} value={userEmails}>
                                        {userEmails}
                                    </MenuItem>
                                ))}
                        </Select>
                        {currentRequestId !== 0 && (
                            <>
                                <InputLabel id="ticketStatus-label">Status</InputLabel>
                                <Select
                                    labelId="ticketStatus-label"
                                    label="Status"
                                    id="status"
                                    value={statusId}
                                    input={<Input />}
                                    sx={searchInputs2Styles}
                                    onChange={(e) => { setStatusId(e.target.value as number) }}
                                >
                                    {dropDownDetails
                                        ?.filter((dropdown: Dropdowns) => dropdown.context === "SUPPORT REQUEST STATUS")
                                        .map((filteredDropdown: Dropdowns) => (
                                            <MenuItem key={filteredDropdown.statusID} value={filteredDropdown.statusID}>
                                                {filteredDropdown.statusName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </>
                        )}
                        {currentRequestDetails?.approvalNeeded?.toUpperCase() === "YES" && (
                            <>
                                <TextField
                                    fullWidth
                                    id="approvalStatus-textarea"
                                    label="Approval Status"
                                    disabled
                                    value={dropDownDetails?.find(x => x.statusID == currentRequestDetails?.approvalStatusId)?.statusName}
                                    variant="standard"
                                    sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        cursor: 'pointer'
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="approvalComment-textarea"
                                    label="Approval Comments"
                                    multiline
                                    disabled
                                    value={currentRequestDetails?.approvalComment}
                                    variant="standard"
                                    sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        cursor: 'pointer'
                                    }}
                                />
                                <DatePicker
                                    label="Approval Date"
                                    value={approvedDate ? dayjs(approvedDate) : null}
                                    disabled
                                    sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: '100%'
                                    }}
                                    onChange={(newValue) => setApprovedDate(newValue.toString())}
                                />
                            </>
                        )}
                        <DatePicker
                            label="Planned Due Date"
                            value={plannedDueDate ? dayjs(plannedDueDate) : null}
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: '100%'
                            }}
                            onChange={(newValue) => setPlannedDueDate(newValue.toString())}
                        />
                        <DatePicker
                            label="Completed Date"
                            value={completedDate ? dayjs(completedDate) : null}
                            sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: '100%'
                            }}
                            onChange={(newValue) => setCompletedDate(newValue.toString())}
                        />
                    </>
                )}
                {currentRequestDetails?.attachments.length > 0 && (
                    <>
                        <Typography variant="h6" gutterBottom
                            sx={{
                                fontSize: '1rem',
                                marginTop: '20px',
                                marginBottom: '0px',
                            }}>
                            Download Attachments
                        </Typography>
                        <Divider />
                        <List>
                            {currentRequestDetails?.attachments?.map((file, index) => (
                                <ListItem key={file.attachmentId}
                                    sx={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        paddingTop: '0px'
                                    }}>
                                    <ListItemText
                                        primary={file.fileName}
                                        sx={{
                                            marginRight: '20px'
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => { handleFileDownload(file.attachmentId, file.fileName) }}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {files.length > 0 && (
                    <>
                        <Typography variant="h6" gutterBottom
                            sx={{
                                fontSize: '1rem',
                                marginTop: '20px'
                            }}>
                            New Files to Upload
                        </Typography>
                        <Divider />
                        <List>
                            {files.map((file, index) => (
                                <ListItem key={index}
                                    sx={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        paddingTop: '0px',
                                    }}>
                                    <ListItemText
                                        primary={file.name}
                                        sx={{
                                            marginRight: '20px'
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => handleDeleteFile(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {allowUpdation() &&
                    <FormControl fullWidth>
                        <Button
                            variant="contained"
                            component="span"
                            onClick={handleButtonClick}
                            startIcon={<AttachFileIcon />}
                            sx={{
                                marginBottom: '10px'
                            }}
                        >
                            Select Files
                        </Button>
                        <input
                            ref={fileInputRef}
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            multiple
                        />
                    </FormControl>
                }
            </Container>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {currentRequestId === 0 &&
                    <Button
                        onClick={handleCreate}
                        sx={{
                            mt: 2,
                            backgroundColor: tenantTheme?.Color1 || '#0d4557',
                            color: tenantTheme?.Color3 || '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiSvgIcon-root': {
                                marginLeft: '8px',
                            },
                            '&:hover': {
                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                            },
                        }} >Create</Button>
                }
                {(currentRequestId !== 0 && allowUpdation()) &&
                    <Button
                        onClick={handleUpdate}
                        sx={{
                            mt: 2,
                            backgroundColor: tenantTheme?.Color1 || '#0d4557',
                            color: tenantTheme?.Color3 || '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiSvgIcon-root': {
                                marginLeft: '8px',
                            },
                            '&:hover': {
                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                            },
                        }}>Update</Button>
                }
                {
                    currentRequestId === 0 &&
                    <Button
                        onClick={clearInputs}
                        sx={{
                            marginTop: '5px',
                            backgroundColor: tenantTheme?.Color2 || '#018b8b',
                            color: tenantTheme?.Color4 || '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiSvgIcon-root': {
                                marginLeft: '8px',
                            },
                            '&:hover': {
                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                            },
                        }}> Clear
                    </Button>
                }
            </Box>
        </>
    )
}

export default TicketView;