import Article from '@mui/icons-material/Article';
import Bookmark from '@mui/icons-material/Bookmark';
import Download from '@mui/icons-material/Download';
import Edit from '@mui/icons-material/Edit';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FitScreen from '@mui/icons-material/FitScreen';
import Fullscreen from '@mui/icons-material/Fullscreen';
import Image from '@mui/icons-material/Image';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import Pageview from '@mui/icons-material/Pageview';
import PhotoSizeSelectActual from '@mui/icons-material/PhotoSizeSelectActual';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Refresh from '@mui/icons-material/Refresh';
import Save from '@mui/icons-material/Save';
import SaveAs from '@mui/icons-material/SaveAs';
import Slideshow from '@mui/icons-material/Slideshow';
import SyncAlt from '@mui/icons-material/SyncAlt';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Visibility from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AnimatePresence, motion } from 'framer-motion';
import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../AppContext";
import { ExportFileRequest } from '../../../models/models';
import AppOwnsDataWebApi from './../../../services/AppOwnsDataWebApi';
import { ViewMode } from './../Report';
require('powerbi-models'); // ensure Power BI JavaScript API has loaded
require('powerbi-client'); // ensure Power BI JavaScript API has loaded

const menuButtonStyles = {
    color: "#0d4557",
    fontSize: "9px",
    ml: 1
};

const menuItemStyles = {
    fontSize: "11px",
    px: 1.5,
    py: 0, m: 0
};

const menuSwitchStyles = {
    ml: "auto",
    pl: 1
};

const menuRadioButtonStyles = {
    ml: "auto",
    pl: 1.5
};

const reportExportProgressStyles = {
    backgroundColor: "#0d4557",
    display: "block",
    verticalAlign: "middle",
    width: "100%"
};

const reportToolBarContainerStyles = {
    backgroundColor: "white",
    width: 1,
    p: 0,
    m: 0,
    zIndex: 1,
    position: 'absolute',
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.14)"
};

const reportToolBarStyles = {
    p: 0,
    m: 0,
    minHeight: "32px"
};

const saveIconsStyles = {
    color: '#0d4557',
    mr: 1
};

const divider1Styles = {
    my: 0.5
}

const divider2Styles = {
    backgroundColor: "#666666",
    borderWidth: "1px",
    my: 0.5,
}

const divider3Styles = {
    ml: "auto"
}

const divider4Styles = {
    mr: 1
}

const exportIconsStyles = {
    color: '#0d4557',
    mr: 1
}

const viewIconsStyles = {
    color: '#0d4557',
    mr: 1
}

const resixeIconsStyles = {
    color: '#0d4557',
    mr: 1
}


interface ReportToolbarProps {
    report: powerbi.Report;
    editMode: boolean;
    setEditMode: (EditMode: boolean) => void;
    showNavigation: boolean;
    setShowNavigation: (ShowNavigation: boolean) => void;
    showFiltersPane: boolean;
    setShowFiltersPane: (ShowFiltersPane: boolean) => void;
    showBookmarksPane: boolean;
    setShowBookmarksPane: (ShowBookmarksPane: boolean) => void;
    viewMode: ViewMode;
    setViewMode: (VeiwModeValue: ViewMode) => void;
    setEmbedToken: (embedToken: string) => void;
    setEmbedTokenExpiration: (embedTokenExpiration: string) => void;
}

const ReportToolbar = (
    {
        report,
        editMode,
        setEditMode,
        showNavigation,
        setShowNavigation,
        showFiltersPane,
        setShowFiltersPane,
        showBookmarksPane,
        setShowBookmarksPane,
        viewMode,
        setViewMode
    }: ReportToolbarProps) => {

    const { embeddingData, isSuperUser, tenantTheme } = useContext(AppContext);
    const [anchorElementFile, setAnchorElementFile] = useState<null | HTMLElement>(null);
    const [anchorElementExport, setAnchorElementExport] = useState<null | HTMLElement>(null);
    const [anchorElementView, setAnchorElementView] = useState<null | HTMLElement>(null);
    const [anchorElementViewMode, setAnchorElementViewMode] = useState<null | HTMLElement>(null);
    const [openSaveAsDialog, setOpenSaveAsDialog] = useState(false);
    const [newReportName, setNewReportName] = useState("");
    const [openExportProgressDialog, setOpenExportProgressDialog] = useState(false);
    const [isToolbarVisible, setIsToolbarVisible] = useState(false);
    const navigate = useNavigate();
    const refReportName = useRef<HTMLInputElement>(null);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const mobileIconButtonStyles = {
        color: '#0d4557',
        position: 'absolute',
        top: isToolbarVisible ? '83%' : '5%',
        right: 0, zIndex: 1
    };

    const exportLinearProgressStyles = {
        backgroundColor: tenantTheme?.Color1 || "#0d4557",
    };

    const toggleToolbarVisibility = () => {
        setIsToolbarVisible(!isToolbarVisible);
    };

    const onFileSave = () => {
        setAnchorElementFile(null);
        report.save()
    };

    const onFileSaveAs = () => {
        setAnchorElementFile(null);
        setNewReportName("")
        setOpenSaveAsDialog(true);
    };

    const onExportPageToPDF = async () => {

        // close Export menu and open export progress dialog
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);

        // get report data for ExportFile operation
        let reportId = report.getId();
        let currentPage = await report.getActivePage();
        let currentPageName = currentPage.name;
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });

        // create ExportFileRequest variable with parameters for export
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PDF",
            BookmarkState: bookmark.state,
            PageName: currentPageName,
        };

        // Call ExportFile from AppOwnsDataWebApi
        await AppOwnsDataWebApi.ExportFile(exportRequest);

        // close export progress dialog
        setOpenExportProgressDialog(false);

    };

    const onExportPageToPNG = async () => {
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);
        let reportId = report.getId();
        let currentPage = await report.getActivePage();
        let currentPageName = currentPage.name;
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PNG",
            PageName: currentPageName,
            BookmarkState: bookmark.state
        };
        await AppOwnsDataWebApi.ExportFile(exportRequest);
        setOpenExportProgressDialog(false);
    };

    const onExportPageToPPTX = async () => {
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);
        let reportId = report.getId();
        let currentPage = await report.getActivePage();
        let currentPageName = currentPage.name;
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PPTX",
            PageName: currentPageName,
            BookmarkState: bookmark.state
        };
        await AppOwnsDataWebApi.ExportFile(exportRequest);
        setOpenExportProgressDialog(false);
    };

    const onExportReportToPDF = async () => {
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);

        let reportId = report.getId();
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });

        // create ExportFileRequest variable with parameters for export
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PDF",
            BookmarkState: bookmark.state
        };

        // Call ExportFile from AppOwnsDataWebApi
        await AppOwnsDataWebApi.ExportFile(exportRequest);

        // close dialog
        setOpenExportProgressDialog(false);
    };

    const onExportReportToPNG = async () => {
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);
        let reportId = report.getId();
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PNG",
            BookmarkState: bookmark.state
        };
        await AppOwnsDataWebApi.ExportFile(exportRequest);
        setOpenExportProgressDialog(false);
    };

    const onExportReportToPPTX = async () => {
        setAnchorElementExport(null);
        setOpenExportProgressDialog(true);
        let reportId = report.getId();
        let currentPage = await report.getActivePage();
        let currentPageName = currentPage.name;
        let bookmark = await report.bookmarksManager.capture({ allPages: false, personalizeVisuals: false });
        const exportRequest: ExportFileRequest = {
            ReportId: reportId,
            ExportType: "PPTX",
            PageName: currentPageName,
            BookmarkState: bookmark.state
        };
        await AppOwnsDataWebApi.ExportFile(exportRequest);
        setOpenExportProgressDialog(false);
    };

    const onViewToggleNavigation = () => {
        setShowNavigation(!showNavigation);
        report.updateSettings({
            panes: {
                pageNavigation: { visible: !showNavigation }
            }
        });
    };

    const onViewToggleFilterPane = () => {
        setShowFiltersPane(!showFiltersPane);
        report.updateSettings({
            panes: {
                filters: { visible: !showFiltersPane, expanded: true }
            }
        });
    };

    const onViewToggleBookmarksPane = (args: any) => {
        setShowBookmarksPane(!showBookmarksPane);
        report.updateSettings({
            panes: {
                bookmarks: { visible: !showBookmarksPane }
            }
        });
    };

    const onToggleEditMode = () => {
        report.switchMode(editMode ? "view" : "edit");
        setEditMode(!editMode);
    }

    const onViewModeFitToPage = () => {
        setAnchorElementViewMode(null);
        report.updateSettings({
            layoutType: models.LayoutType.Custom,
            customLayout: { displayOption: models.DisplayOption.FitToPage }
        });
        setViewMode("FitToPage")
    };

    const onViewModeFitToWidth = () => {
        setAnchorElementViewMode(null);
        report.updateSettings({
            layoutType: models.LayoutType.Custom,
            customLayout: { displayOption: models.DisplayOption.FitToWidth }
        });
        setViewMode("FitToWidth")
    };

    const onViewModeActualSize = () => {
        setAnchorElementViewMode(null);
        report.updateSettings({
            layoutType: models.LayoutType.Custom,
            customLayout: { displayOption: models.DisplayOption.ActualSize }
        });
        setViewMode("ActualSize")
    };

    const onReportRefresh = async () => {
        report.refresh();
    };

    const onReportFullscreen = () => {
        report.fullscreen();
    };

    return (
        <>
            <Box sx={reportToolBarContainerStyles} >
                <AnimatePresence>
                    {isToolbarVisible && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3, ease: 'easeInOut' }}
                        >
                            <Toolbar disableGutters variant='dense' sx={reportToolBarStyles} >
                                {editMode && (
                                    <>
                                        <Button
                                            sx={menuButtonStyles}
                                            startIcon={<Article />}
                                            endIcon={<KeyboardArrowDown />}
                                            onClick={(event) => { setAnchorElementFile(event.currentTarget); }} >
                                            File
                                        </Button>
                                        <Menu
                                            sx={menuItemStyles}
                                            anchorEl={anchorElementFile}
                                            open={Boolean(anchorElementFile)}
                                            onClose={() => { setAnchorElementFile(null); }}
                                        >
                                            <MenuItem sx={menuItemStyles} onClick={onFileSave} disableRipple >
                                                <Save sx={saveIconsStyles} /> Save
                                            </MenuItem>
                                            {embeddingData.userCanCreate && [
                                                <Divider sx={divider1Styles} />,
                                                <MenuItem sx={menuItemStyles} onClick={onFileSaveAs} disableRipple >
                                                    <SaveAs sx={saveIconsStyles} /> Save As
                                                </MenuItem>,
                                            ]}
                                        </Menu>
                                        <Divider orientation='vertical' flexItem />
                                    </>
                                )}
                                {/*<>*/}
                                {/*    <Button*/}
                                {/*        sx={menuButtonStyles}*/}
                                {/*        startIcon={<Download />}*/}
                                {/*        endIcon={<KeyboardArrowDown />}*/}
                                {/*        onClick={(event) => { setAnchorElementExport(event.currentTarget); }} >*/}
                                {/*        Export*/}
                                {/*    </Button>*/}
                                {/*    <Menu*/}
                                {/*        sx={menuItemStyles}*/}
                                {/*        anchorEl={anchorElementExport}*/}
                                {/*        open={Boolean(anchorElementExport)}*/}
                                {/*        onClose={() => { setAnchorElementExport(null); }}*/}
                                {/*    >*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportPageToPDF}   >*/}
                                {/*            <PictureAsPdf sx={exportIconsStyles} /> Export Current Page to PDF*/}
                                {/*        </MenuItem>*/}
                                {/*        <Divider sx={divider1Styles} />*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportPageToPNG} disableRipple >*/}
                                {/*            <Image sx={exportIconsStyles} /> Export Current Page to PNG*/}
                                {/*        </MenuItem>*/}
                                {/*        <Divider sx={divider1Styles} />*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportPageToPPTX} disableRipple >*/}
                                {/*            <Slideshow sx={exportIconsStyles} /> Export Current Page to PowerPoint (PPTX)*/}
                                {/*        </MenuItem>*/}
                                {/*        <Divider sx={divider2Styles} />*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportReportToPDF}  >*/}
                                {/*            <PictureAsPdf sx={exportIconsStyles} /> Export Report to PDF*/}
                                {/*        </MenuItem>*/}
                                {/*        <Divider sx={divider1Styles} />*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportReportToPNG} disableRipple >*/}
                                {/*            <Image sx={exportIconsStyles} /> Export Report to PNG*/}
                                {/*        </MenuItem>*/}
                                {/*        <Divider sx={divider1Styles} />*/}
                                {/*        <MenuItem sx={menuItemStyles} onClick={onExportReportToPPTX} disableRipple >*/}
                                {/*            <Slideshow sx={exportIconsStyles} /> Export Report to PowerPoint (PPTX)*/}
                                {/*        </MenuItem>*/}
                                {/*    </Menu>*/}
                                {/*    <Divider orientation='vertical' flexItem />*/}
                                {/*</>*/}
                                <Button startIcon={<Visibility />} endIcon={<KeyboardArrowDown />} sx={menuButtonStyles}
                                    onClick={(event) => { setAnchorElementView(event.currentTarget); }} >
                                    View
                                </Button>
                                <Menu
                                    sx={menuItemStyles}
                                    anchorEl={anchorElementView}
                                    open={Boolean(anchorElementView)}
                                    onClose={() => { setAnchorElementView(null); }}
                                >
                                    <MenuItem sx={menuItemStyles} disableRipple >
                                        <MenuIcon sx={viewIconsStyles} /> Navigation Menu <Switch sx={menuSwitchStyles} checked={showNavigation} onChange={onViewToggleNavigation} />
                                    </MenuItem>
                                    <Divider sx={divider1Styles} />
                                    <MenuItem sx={menuItemStyles} disableRipple >
                                        <FilterAlt sx={viewIconsStyles} /> Filter Pane <Switch sx={menuSwitchStyles} checked={showFiltersPane} onChange={onViewToggleFilterPane} />
                                    </MenuItem>
                                    <Divider sx={divider1Styles} />
                                    <MenuItem sx={menuItemStyles} disableRipple >
                                        <Bookmark sx={viewIconsStyles} /> Bookmarks Pane<Switch sx={menuSwitchStyles} checked={showBookmarksPane} onChange={onViewToggleBookmarksPane} />
                                    </MenuItem>
                                </Menu>
                                <Divider orientation='vertical' flexItem />
                                {embeddingData.userCanEdit &&
                                    <>
                                        <Button
                                            sx={menuButtonStyles}
                                            startIcon={editMode ? <Pageview /> : <Edit />}
                                            onClick={onToggleEditMode} >
                                            {editMode ? "Reading View" : "Edit"}
                                        </Button>
                                        <Divider orientation='vertical' flexItem />
                                    </>
                                }
                                <Divider orientation='vertical' flexItem sx={divider3Styles} />
                                {isSuperUser && (
                                    <>
                                        <Button
                                            sx={menuButtonStyles}
                                            startIcon={<ManageAccountsIcon />}
                                            onClick={(event) => { navigate("/usermanagement"); }} >
                                            User Management
                                        </Button>
                                        <Divider orientation='vertical' flexItem />
                                    </>
                                )}
                                <Button
                                    sx={menuButtonStyles}
                                    startIcon={<FitScreen />}
                                    endIcon={<KeyboardArrowDown />}
                                    onClick={(event) => { setAnchorElementViewMode(event.currentTarget); }} >
                                    View Mode
                                </Button>
                                <Menu
                                    sx={menuItemStyles}
                                    anchorEl={anchorElementViewMode}
                                    open={Boolean(anchorElementViewMode)}
                                    onClose={() => { setAnchorElementViewMode(null); }}
                                >
                                    <MenuItem onClick={onViewModeFitToPage} disableRipple sx={menuItemStyles}  >
                                        <FitScreen sx={resixeIconsStyles} /> Fit to Page {viewMode === "FitToPage" ? <RadioButtonChecked sx={menuRadioButtonStyles} /> : <RadioButtonUnchecked sx={menuRadioButtonStyles} />}
                                    </MenuItem>
                                    <Divider sx={divider1Styles} />
                                    <MenuItem onClick={onViewModeFitToWidth} disableRipple sx={menuItemStyles} >
                                        <SyncAlt sx={resixeIconsStyles} /> Fit to Width {viewMode === "FitToWidth" ? <RadioButtonChecked sx={menuRadioButtonStyles} /> : <RadioButtonUnchecked sx={menuRadioButtonStyles} />}
                                    </MenuItem>
                                    <Divider sx={divider1Styles} />
                                    <MenuItem onClick={onViewModeActualSize} disableRipple sx={menuItemStyles} >
                                        <PhotoSizeSelectActual sx={resixeIconsStyles} /> Actual Size {viewMode === "ActualSize" ? <RadioButtonChecked sx={menuRadioButtonStyles} /> : <RadioButtonUnchecked sx={menuRadioButtonStyles} />}
                                    </MenuItem>
                                </Menu>
                                <Divider orientation='vertical' flexItem />
                                <Button startIcon={<Refresh />} sx={menuButtonStyles} onClick={onReportRefresh} >Refresh</Button>
                                <Divider orientation='vertical' flexItem />
                                <Button startIcon={<Fullscreen />} sx={menuButtonStyles} onClick={onReportFullscreen}  >Full Screen</Button>
                                <Divider orientation='vertical' flexItem sx={divider4Styles} />
                            </Toolbar>
                        </motion.div>
                    )}
                </AnimatePresence>
                {!isMobile &&
                    <IconButton
                        sx={mobileIconButtonStyles}
                        color="inherit"
                        aria-label="toggle toolbar visibility"
                        onClick={toggleToolbarVisibility}
                    >
                        <UnfoldMoreIcon />
                    </IconButton>
                }
            </Box>

            <Dialog open={openSaveAsDialog} onClose={() => { setOpenSaveAsDialog(false); }} >
                <DialogTitle>Enter report name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You need to give this new report a name.
                    </DialogContentText>
                    <TextField
                        onChange={(event) => { setNewReportName(event.target.value) }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="New Report Name"
                        type="text"
                        value={newReportName}
                        fullWidth
                        variant="standard"
                        inputRef={refReportName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={newReportName === ""} onClick={async () => {
                        setOpenSaveAsDialog(false);
                        await report.saveAs({ name: newReportName });
                    }}>Save</Button>
                    <Button onClick={() => { setOpenSaveAsDialog(false); }}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openExportProgressDialog} onClose={() => { setOpenExportProgressDialog(false); }} >
                <DialogTitle>Power BI Report Export Job in Progress</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={reportExportProgressStyles} >
                        <LinearProgress sx={exportLinearProgressStyles} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenExportProgressDialog(false); }} >Dismiss</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportToolbar