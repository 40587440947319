import styled, { keyframes } from 'styled-components';
import { isDate, parseISO } from 'date-fns';
import format from 'date-fns/format';

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const StyledSpinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;
`;

const CustomLoader = () => (
    <div style={{ padding: '24px' }}>
        <StyledSpinner />
        <div> Loading Data...</div>
    </div>
);

const customStyles = {
    headCells: {
        style: {
            color: 'white',
            backgroundColor: '#0d4557'
        },
    },
};

const languages = [
    { code: 'ar-SA', name: 'العربية (Arabic)' },
    { code: 'bg-BG', name: 'български (Bulgarian)' },
    { code: 'ca-ES', name: 'català (Catalan)' },
    { code: 'cs-CZ', name: 'čeština (Czech)' },
    { code: 'da-DK', name: 'dansk (Danish)' },
    { code: 'de-DE', name: 'Deutsche (German)' },
    { code: 'el-GR', name: 'ελληνικά (Greek)' },
    { code: 'en-US', name: 'English (English)' },
    { code: 'es-ES', name: 'español service (Spanish)' },
    { code: 'et-EE', name: 'eesti (Estonian)' },
    { code: 'eU-ES', name: 'Euskal (Basque)' },
    { code: 'fi-FI', name: 'suomi (Finnish)' },
    { code: 'fr-FR', name: 'français (French)' },
    { code: 'gl-ES', name: 'galego (Galician)' },
    { code: 'he-IL', name: 'עברית (Hebrew)' },
    { code: 'hi-IN', name: 'हिन्दी (Hindi)' },
    { code: 'hr-HR', name: 'hrvatski (Croatian)' },
    { code: 'hu-HU', name: 'magyar (Hungarian)' },
    { code: 'id-ID', name: 'Bahasa Indonesia (Indonesian)' },
    { code: 'it-IT', name: 'italiano (Italian)' },
    { code: 'ja-JP', name: '日本の (Japanese)' },
    { code: 'kk-KZ', name: 'Қазақ (Kazakh)' },
    { code: 'ko-KR', name: '한국의 (Korean)' },
    { code: 'lt-LT', name: 'Lietuvos (Lithuanian)' },
    { code: 'lv-LV', name: 'Latvijas (Latvian)' },
    { code: 'ms-MY', name: 'Bahasa Melayu (Malay)' },
    { code: 'nb-NO', name: 'norsk (Norwegian)' },
    { code: 'nl-NL', name: 'Nederlands (Dutch)' },
    { code: 'pl-PL', name: 'polski (Polish)' },
    { code: 'pt-BR', name: 'português (Portuguese)' },
    { code: 'pt-PT', name: 'português (Portuguese)' },
    { code: 'ro-RO', name: 'românesc (Romanian)' },
    { code: 'ru-RU', name: 'русский (Russian)' },
    { code: 'sk-SK', name: 'slovenský (Slovak)' },
    { code: 'sl-SI', name: 'slovenski (Slovenian)' },
    { code: 'sr-Cyrl-RS', name: 'српски (Serbian)' },
    { code: 'sr-Latn-RS', name: 'srpski (Serbian)' },
    { code: 'sv-SE', name: 'svenska (Swedish)' },
    { code: 'th-TH', name: 'ไทย (Thai)' },
    { code: 'tr-TR', name: 'Türk (Turkish)' },
    { code: 'uk-UA', name: 'український (Ukrainian)' },
    { code: 'vi-VN', name: 'tiếng Việt (Vietnamese)' },
    { code: 'zh-CN', name: '中国 (Chinese-Simplified)' },
    { code: 'zh-TW', name: '中國 (Chinese-Tranditional)' },
];

const formatDate = (isoDate: any): string => {
    if (!isoDate) {
        return '';
    }

    const date = isDate(isoDate) ? isoDate : parseISO(isoDate);
    const formattedDate = isDate(date) ? format(date, 'do MMM yyyy') : isoDate;

    return formattedDate;
};


export { CustomLoader, customStyles, languages, formatDate };


