import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DraftsIcon from '@mui/icons-material/Drafts';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box, Button, Checkbox, Container, FormControl, Grid, IconButton, Input, MenuItem, Select, Stack, Typography
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ChangeEvent, useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from '../../AppContext';
import { MangeUsersRowData, Reports } from '../../models/models';
import AppOwnsDataWebApi from '../../services/AppOwnsDataWebApi';
import { CustomLoader, languages } from '../CommonComponents';
import ReportAssignment from '../ReportAssignment';
import Unauthenticated from '../Unauthenticated';
import Unauthorized from '../Unauthorized';
import UserCreation from '../UserCreation';

const UserManagement = () => {
    const navigate = useNavigate();
    const [pending, setPending] = React.useState(true);
    const [mangeUserDetails, setMangeUserDetails] = React.useState([]);
    const [mangeFilteredUserDetails, setFilteredMangeUserDetails] = React.useState([]);
    const { setDisplayMainLoader, tenantTheme, embeddingData } = useContext(AppContext);
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [searchedUserName, setSearchedUserName] = React.useState('');
    const [searchedLoginId, setSearchedLoginId] = React.useState('');
    const [hasPermissions, setHasPermissions] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //Checking Permissions

    const [userCreationModalOpen, setUserCreationModalOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);

    const [showReportsTab, setShowReportsTab] = React.useState(false);
    const [currentUserForReportAssignment, setCurrentUserForReportAssignment] = React.useState("");
    const [manageReports, setManageReports] = React.useState<Reports[]>([]);
    const [manageFilteredReports, setFilteredManageReports] = React.useState<Reports[]>([]);
    const [assignedReportIds, setAssignedReportIds] = React.useState([]);
    const [configuredTenantRoles, setConfiguredTenantRoles] = React.useState([]);

    const CheckUserPermissions = async () => {
        const respToProceed = await AppOwnsDataWebApi.IsValidUser(account.username, ["IS_SUPER_USER"]);
        if (respToProceed === true) {
            setHasPermissions(true);
        } else {
            setHasPermissions(false);
        }
        setIsLoading(false);
    };

    const FetchMangeUserDetails = async () => {
        try {
            const response = await AppOwnsDataWebApi.GetUsers(account.username);
            setMangeUserDetails([...response.users]);
            setConfiguredTenantRoles([...response.tenantRoles]);
            setPending(false);
        } catch (error) {
            setPending(false);
        }
    };

    const handleNewUserCreationClickOpen = () => {
        setUserName('');
        setEmail('');
        setShowValidationMessage(false);
        setUserCreationModalOpen(true);
    };

    const UpdateReportLanguageCode = async (loginId: string, languageCode: string) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.UpdateUserReportLanguageCode(loginId, languageCode);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsers(account.username),
                    {
                        success: 'Report Language Updated',
                        error: 'An Error Occured'
                    }
                );
                setMangeUserDetails([...response.users]);
                embeddingData.setReportLanguageCode(languageCode);
            }
            else {
                toast.error("An Unexpected Error Occured!");
            }
            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    const UpdateReportRole = async (loginId: string, reportCodeId: number) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.UpdateUserReportRole(loginId, reportCodeId);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsers(account.username),
                    {
                        success: 'Role Updated',
                        error: 'An Error Occured'
                    }
                );
                setMangeUserDetails([...response.users]);
            }
            else {
                toast.error("An Unexpected Error Occured!");
            }
            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    useEffect(() => {
        try {
            if (!isAuthenticated)
                return;

            CheckUserPermissions();
        } catch (e) {
            setIsLoading(false);
            setDisplayMainLoader(false);
        }
    }, [])


    useEffect(() => {
        try {
            if (hasPermissions == false)
                return;

            FetchMangeUserDetails();
        } catch (e) {
            setDisplayMainLoader(false);
        }

    }, [hasPermissions])

    useEffect(() => {
        filterSearch();
    }, [mangeUserDetails])

    const handleRightsAssignment = async (rightType: string, userBeingManaged: string) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.ToggleUserRights(userBeingManaged, rightType);
            if (response === true) {
                const response = await toast.promise(
                    AppOwnsDataWebApi.GetUsers(account.username),
                    {
                        success: 'User Right Updated',
                        error: 'An Error Occured'
                    }
                );
                setMangeUserDetails([...response.users]);
            }
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    const generateEmail = () => {
        var tenantEmailIds = mangeUserDetails.map(x => x.loginId);
        const toEmailAddress = tenantEmailIds?.join(';');
        const mailtoLink = `mailto:${toEmailAddress}`;
        window.location.href = mailtoLink;
    };

    const getUserReportsToManage = async (mangedUserName: string) => {
        try {
            setDisplayMainLoader(true);
            setShowReportsTab(false);
            setCurrentUserForReportAssignment(mangedUserName);
            const response = await AppOwnsDataWebApi.GetUserReports(mangedUserName);
            setManageReports([...response.allReports]);
            setFilteredManageReports([...response.allReports]);
            setAssignedReportIds(response.allReports.map((x) => {
                if (x.isAssigned === true) {
                    return x.reportID
                }
            }).filter(reportId => reportId !== undefined));
            setShowReportsTab(true);
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
            setPending(false);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            filterSearch();
        }
    };

    const filterSearch = () => {
        let filteredData = mangeUserDetails;
        if (searchedUserName) {
            filteredData = filteredData.filter((user) =>
                user.userName?.toLowerCase().includes(searchedUserName.toLowerCase())
            );
        }

        if (searchedLoginId) {
            filteredData = filteredData.filter((user) =>
                user.loginId?.toLowerCase().includes(searchedLoginId.toLowerCase())
            );
        }

        setFilteredMangeUserDetails(filteredData);
    }

    const clearFilters = () => {
        setFilteredMangeUserDetails(mangeUserDetails);
        setSearchedLoginId('');
        setSearchedUserName('');
        const searchByUsernameInput = document.getElementById('search-username') as HTMLInputElement;
        if (searchByUsernameInput) {
            searchByUsernameInput.value = '';
        }

        const searchByLoginIdInput = document.getElementById('search-loginId') as HTMLInputElement;
        if (searchByLoginIdInput) {
            searchByLoginIdInput.value = '';
        }
    };

    const manageUserColumns = [
        {
            name: 'LOGIN ID',
            minWidth: "230px",
            selector: (row: MangeUsersRowData) => row.loginId,
            sortable: true,
        },
        {
            name: 'USERNAME',
            minWidth: "230px",
            selector: (row: MangeUsersRowData) => row.userName,
            sortable: true,
        },
        {
            name: 'LAST LOGIN',
            minWidth: "150px",
            selector: (row: MangeUsersRowData) => row.lastLogin,
            sortable: true,
            format: (row: MangeUsersRowData) => {
                if (row.lastLogin === '0001-01-01T00:00:00') {
                    return '';
                }
                const date = new Date(row.lastLogin);
                const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
                const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                const formattedDateTime = `${formattedDate} ${formattedTime}`;
                return formattedDateTime;
            },
        },
        {
            name: 'ROLE',
            minWidth: "300px",
            cell: (row: MangeUsersRowData) => (
                <FormControl size="small" variant='standard'>
                    <Stack direction="row" spacing={1}>
                        <Select
                            id={row.loginId}
                            value={row.roleId || 0}
                            onChange={(e) => { UpdateReportRole(row.loginId, e.target.value as number) }}
                            sx={{
                                color: 'black',
                                width: '250px',
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                Unassigned
                            </MenuItem>
                            {configuredTenantRoles?.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </FormControl>
            ),
        },
        {
            name: 'CAN EDIT',
            cell: (row: MangeUsersRowData) => (
                <Checkbox checked={row.canEdit} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("edit", row.loginId); }} />
            ),
        },
        {
            name: 'CAN CREATE',
            cell: (row: MangeUsersRowData) => (
                <Checkbox checked={row.canCreate} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("create", row.loginId); }} />
            ),
        },
        {
            name: 'SUPER ADMIN',
            cell: (row: MangeUsersRowData) => (
                <Checkbox checked={row.isSuperUser} style={{ color: (tenantTheme?.Color1 || '#0d4557') }} onClick={() => { handleRightsAssignment("super", row.loginId); }} />
            ),
        },
        {
            name: 'REPORT LANGUAGE',
            minWidth: "300px",
            cell: (row: MangeUsersRowData) => (
                <FormControl size="small" variant='standard'>
                    <Stack direction="row" spacing={1}>
                        <Select
                            id={row.lastLogin}
                            value={row.reportLanguageCode}
                            onChange={(e) => { UpdateReportLanguageCode(row.loginId, e.target.value) }}
                            sx={{
                                color: 'black',
                                width: '250px',
                            }}
                        >
                            {languages.map((language) => (
                                <MenuItem key={language.code} value={language.code}>
                                    {language.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </FormControl>
            ),
        },
        {
            name: 'REPORTS',
            button: true,
            cell: (row: MangeUsersRowData) => (
                <Button
                    variant="contained"
                    sx={{
                        color: tenantTheme?.Color4 || '#FFFFFF',
                        backgroundColor: tenantTheme?.Color1 || '#0d4557',
                        '&:hover': {
                            backgroundColor: tenantTheme?.Color2 || '#018b8b',
                        },
                    }}
                    onClick={() => { getUserReportsToManage(row.loginId) }}
                >Assign</Button >
            )
        }
    ];

    if (!isAuthenticated) {
        return <Unauthenticated />;
    }

    if (isLoading === true) {
        return null;
    }

    if (isLoading === false && !hasPermissions) {
        return <Unauthorized />;
    }

    if (isAuthenticated && hasPermissions) {
        return (
            <>
                <Container maxWidth={false}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    {showReportsTab === false && (
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                            >
                                <Typography variant='h5' component="h2" sx={{ mt: 2, mb: "6px", textAlign: 'center', fontWeight: '500px' }} >USER MANAGEMENT</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9}>
                                        {pending === false && (
                                            <>
                                                <Button
                                                    startIcon={<DraftsIcon />}
                                                    variant="contained"
                                                    disabled={mangeUserDetails.length > 0 ? false : true}
                                                    sx={{
                                                        backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                        float: 'right',
                                                        marginBottom: "4px",
                                                        color: tenantTheme?.Color4 || '#FFFFFF',
                                                        '&:hover': {
                                                            backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                                            color: (tenantTheme?.Color5 || '#00ffa9'),
                                                        },
                                                    }}
                                                    onClick={() => { generateEmail() }}>
                                                    @ Mail
                                                </Button>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "4px" }}>
                                                    <IconButton sx={{
                                                        color: tenantTheme?.Color1 || '#0d4557',
                                                    }} onClick={() => {
                                                        navigate("/reports/" + embeddingData?.pageLoadReportId);
                                                    }}>
                                                        <ArrowBackIcon />
                                                    </IconButton>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                        Back to Reports
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                        <DataTable
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        color: tenantTheme?.Color3 || '#FFFFFF',
                                                        backgroundColor: tenantTheme?.Color1 || '#0d4557'
                                                    },
                                                },
                                            }}
                                            columns={manageUserColumns}
                                            data={mangeFilteredUserDetails}
                                            pagination
                                            progressPending={pending}
                                            progressComponent={<CustomLoader />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={{ display: 'flex', height: '89vh' }}>
                                        <Container sx={{ backgroundColor: '#f0f0f0', p: 2, boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);', height: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                                                <FilterAltIcon sx={{ marginRight: '5px', color: '#0d4557' }} />
                                                <Typography variant="h6" sx={{ fontWeight: 'lighter' }}>APPLY FILTERS</Typography>
                                            </Box>
                                            <Container
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '6px',
                                                    boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                                                    marginBottom: '15px',
                                                    borderLeft: '4px solid' + (tenantTheme?.Color5 || '#00ffa9'),
                                                }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                                                    <GroupsIcon sx={{ marginRight: '5px', color: '#0d4557' }} />
                                                    <Typography variant="h6" sx={{ fontWeight: 'lighter' }}>
                                                        Total Users: {mangeFilteredUserDetails?.length ? mangeFilteredUserDetails.length : 0}
                                                    </Typography>
                                                </Box>
                                            </Container>
                                            <Container
                                                sx={{
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '6px',
                                                    boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                                                    borderLeft: '4px solid' + (tenantTheme?.Color5 || '#00ffa9'),
                                                }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '30px' }}>
                                                    <Input
                                                        id="search-username"
                                                        onKeyPress={handleKeyPress}
                                                        sx={{ ml: 1, flex: 1 }}
                                                        placeholder="Search By User Name"
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setSearchedUserName(e.target.value) }}
                                                        inputProps={{ 'aria-label': 'Search By User Name' }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton type="button" aria-label="search">
                                                                    <SearchIcon sx={{ color: (tenantTheme?.Color1 || '#0d4557') }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '30px', paddingBottom: '30px' }}>
                                                    <Input
                                                        id="search-loginId"
                                                        onKeyPress={handleKeyPress}
                                                        sx={{ ml: 1, flex: 1 }}
                                                        placeholder="Search By User Login ID"
                                                        inputProps={{ 'aria-label': 'Search By User Login ID' }}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => { setSearchedLoginId(e.target.value) }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton type="button" aria-label="search">
                                                                    <SearchIcon sx={{ color: (tenantTheme?.Color1 || '#0d4557') }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Box>
                                            </Container>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Button
                                                    onClick={filterSearch}
                                                    sx={{
                                                        mt: 2,
                                                        backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                                        color: tenantTheme?.Color3 || '#FFFFFF',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& .MuiSvgIcon-root': {
                                                            marginLeft: '8px',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                                        },
                                                    }}> Apply Filters
                                                </Button>
                                                <Button
                                                    onClick={clearFilters}
                                                    sx={{
                                                        marginTop: '5px',
                                                        backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                        color: tenantTheme?.Color4 || '#FFFFFF',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& .MuiSvgIcon-root': {
                                                            marginLeft: '8px',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                        },
                                                    }}> Clear Filters
                                                </Button>
                                                <Button
                                                    onClick={handleNewUserCreationClickOpen}
                                                    sx={{
                                                        marginTop: '5px',
                                                        backgroundColor: tenantTheme?.Color5 || '#00ffa9',
                                                        color: tenantTheme?.Color1 || '#0d4557',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& .MuiSvgIcon-root': {
                                                            marginLeft: '8px',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: tenantTheme?.Color5 || '#00ffa9',
                                                        },
                                                    }}> Add New User
                                                </Button>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </AnimatePresence>
                    )}
                    {
                        showReportsTab === true && (
                            <ReportAssignment
                                currentUserForReportAssignment={currentUserForReportAssignment}
                                setShowReportsTab={setShowReportsTab}
                                manageReports={manageReports}
                                manageFilteredReports={manageFilteredReports}
                                assignedReportIds={assignedReportIds}
                                setManageReports={setManageReports}
                                setFilteredManageReports={setFilteredManageReports}
                                setAssignedReportIds={setAssignedReportIds}
                                callingScreen={"UserManagement"}
                            />
                        )
                    }

                    <UserCreation
                        userCreationModalOpen={userCreationModalOpen}
                        setUserCreationModalOpen={setUserCreationModalOpen}
                        setEmail={setEmail}
                        email={email}
                        setUserName={setUserName}
                        userName={userName}
                        setShowValidationMessage={setShowValidationMessage}
                        showValidationMessage={showValidationMessage}
                        setMangeUserDetails={setMangeUserDetails}
                        setTenantUserDetails={null}
                        loggedInUser={account.username}
                        callingScreen={"UserManagement"}
                    />
                </Container>
            </>
        )
    }
};

export default UserManagement;