import { useAccount, useMsal } from '@azure/msal-react';
import { Button, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { AppContext } from '../AppContext';
import { Dropdowns } from '../models/models';
import AppOwnsDataWebApi from '../services/AppOwnsDataWebApi';


interface RequestApprovalProps {
    selectedRowsForApproval: number[];
    requestApprovalModalOpen: boolean
    setRequestApprovalModalOpen: Dispatch<SetStateAction<boolean>>;
    approvalStatusValues: Dropdowns[];
    fetchData: (resetView?: number) => Promise<void>;
}

const RequestApproval = ({
    selectedRowsForApproval,
    requestApprovalModalOpen,
    setRequestApprovalModalOpen,
    approvalStatusValues,
    fetchData
}: RequestApprovalProps) => {

    const { setDisplayMainLoader, tenantTheme } = useContext(AppContext);
    const [selectedApprovalStatus, setSelectedApprovalStatus] = React.useState<number>(0);
    const [approvalComment, setApprovalComments] = React.useState<string>('');
    const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const handleApprovalDialogClickClose = () => {
        setApprovalComments('');
        setSelectedApprovalStatus(0);
        setRequestApprovalModalOpen(false);
        setShowValidationMessage(false);
    };

    const handleApprovalSubmission = async () => {
        if (!approvalComment || selectedApprovalStatus === 0) {
            setShowValidationMessage(true);
            return;
        }

        try {
            setShowValidationMessage(false);
            setDisplayMainLoader(true);

            let response = await AppOwnsDataWebApi.ApproveTickets(account.username, selectedRowsForApproval, approvalComment.trim(), selectedApprovalStatus);
            if (response) {
                setRequestApprovalModalOpen(false);
                await fetchData();
            }
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                <Dialog
                    open={requestApprovalModalOpen}
                    onClose={handleApprovalDialogClickClose}>
                    <DialogTitle>APPROVE REQUEST DETAILS</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the below details to approve the requests.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="approvalCommentId"
                            label="Approval Comments *"
                            type="text"
                            multiline
                            fullWidth
                            variant="standard"
                            onChange={(e) => { setApprovalComments(e.target.value as string) }}
                        />
                        <InputLabel
                            id="appovalStatusSelect-label"
                            sx={{
                                marginTop: '20px'

                            }}
                        >Approval Status *</InputLabel>
                        <Select
                            labelId="appovalStatusSelect-label"
                            id="approvalStatusSelect"
                            value={selectedApprovalStatus}
                            onChange={(e) => { setSelectedApprovalStatus(e.target.value as number) }}
                            input={<Input />}
                            sx={{
                                color: '#0d4557',
                                width: '100%',
                                marginTop: '0px',
                                marginBottom: '20px'
                            }}
                        >
                            {approvalStatusValues.map((approvalValues) => (
                                approvalValues.statusName.toUpperCase() !== "WAITING" ?
                                    <MenuItem key={approvalValues.statusID} value={approvalValues.statusID}>
                                        {approvalValues.statusName}
                                    </MenuItem> : null
                            ))}
                        </Select>
                        {showValidationMessage &&
                            <>
                                <AnimatePresence>
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.3, ease: 'easeIn' }}
                                    ><Alert severity="error">Please make sure all the values are entered!</Alert>
                                    </motion.div>
                                </AnimatePresence>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                color: tenantTheme?.Color4 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                },
                            }}
                            onClick={handleApprovalDialogClickClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                color: tenantTheme?.Color3 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                },
                            }}
                            onClick={handleApprovalSubmission}>Approve</Button>
                    </DialogActions>
                </Dialog>
            </motion.div>
        </AnimatePresence>
    );
}

export default RequestApproval;