import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { PowerBiLoginRequest } from "../AuthConfig";

const loginedInMenuContainerBoxStyles = {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto"
}

const loginMenuContainerBoxStyles = {
    marginLeft: "auto",
    marginRight: "12px",
    marginTop: "auto",
    marginBottom: "auto"
}

const dividerStyles = {
    my: 0.5
}

const LoginMenu = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { embeddingData, setDisplayMainLoader, tenantTheme } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const menuItemsAvatarStyles = {
        color: '#000000',
        mr: 1,
    }

    const menuItemStyles = {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        "&:hover": {
            color: '#000000',
            backgroundColor: '#FFFFFF',
        },
        fontWeight: '300',
        padding: '6px',
    }

    const userNameButtonStyles = {
        color: tenantTheme?.Color5 || '#FFFFFF',
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        "&:hover": {
            color: (tenantTheme?.Color5 || '#00ffa9'),
            backgroundColor: tenantTheme?.Color1 || '#0d4557',
        },
        fontSize: "small",
        borderRadius: "8px",
        paddingRight: "20px",
        paddingLeft: "20px",
        mr: "12px;",
        fontWeight: "200",
    }

    const loginButtonStyles = isAuthenticated ? {
        color: tenantTheme?.Color3 || '#FFFFFF',
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        "&:hover": {
            color: (tenantTheme?.Color5 || '#00ffa9'),
            backgroundColor: tenantTheme?.Color1 || '#0d4557',
        },
        borderRadius: "8px",
        paddingRight: "20px",
        paddingLeft: "20px",
    } : {
        color: '#FFFFFF',
        backgroundColor: '#0d4557',
        "&:hover": {
            color: '#00ffa9',
            backgroundColor: '#0d4557',
        },
        borderRadius: "8px",
        paddingRight: "20px",
        paddingLeft: "20px",
    }

    const loginUser = () => {
        instance.loginPopup(PowerBiLoginRequest);
    };

    const logoutUser = () => {
        setDisplayMainLoader(false);
        embeddingData.setRedirectUserToReport(false);
        navigate("/");
        instance.logoutPopup();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isAuthenticated) {
        return (
            <Box sx={loginedInMenuContainerBoxStyles}>
                <Button
                    sx={userNameButtonStyles}
                    disableElevation
                    onClick={handleClick}
                    startIcon={<AccountCircle />}
                    endIcon={<KeyboardArrowDownIcon />} >
                    {account?.name}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate("profile");
                        }}
                        disableRipple
                        sx={menuItemStyles}
                    >
                        <AccountCircle sx={menuItemsAvatarStyles} />
                        User Profile
                    </MenuItem>
                    <Divider sx={dividerStyles} />
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            logoutUser();
                        }}
                        sx={menuItemStyles}
                        disableRipple>
                        <LogoutIcon sx={menuItemsAvatarStyles} />
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        );
    }
    else {
        return (
            <Box sx={loginMenuContainerBoxStyles}>
                <Button
                    onClick={loginUser}
                    startIcon={<LoginIcon />}
                    sx={loginButtonStyles}
                >Login</Button>
            </Box>
        );
    }
}

export default LoginMenu;
