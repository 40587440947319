import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NumbersIcon from '@mui/icons-material/Numbers';
import {
    Box, Button, Checkbox, Chip, Container, Grid, IconButton, Input, MenuItem, Select, Typography
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from '../../AppContext';
import { Dropdowns, Tickets } from '../../models/models';
import AppOwnsDataWebApi from "../../services/AppOwnsDataWebApi";
import { CustomLoader, formatDate } from '../CommonComponents';
import TicketView from '../TicketView';
import Unauthenticated from "../Unauthenticated";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const container1Styles = {
    backgroundColor: '#f0f0f0',
    p: 2,
    boxShadow: '0 10px 6px rgb(0 0 0 / 0.2);',
    height: '100%'
}

const box1Styles = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingTop: '10px',
    paddingBottom: '10px'
}

const box3Styles = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingTop: '30px',
    paddingBottom: '30px'
}


const filterIconStyles = {
    color: '#0d4557',
    marginRight: '5px'
}

const applyFilterTextStyles = {
    fontWeight: 'lighter'
}

const filtersIconStyles = {
    color: '#0d4557',
    marginRight: '5px',
}

const filtersTypoGraphyStyles = {
    fontWeight: 'lighter'
}


const searchInputs2Styles = {
    color: '#0d4557',
    width: '100%'
}

const mainContainerGridStyles = {
    display: 'flex',
    height: '89vh'
}

const UserRequest = () => {
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const [pending, setPending] = React.useState(true);
    const { setDisplayMainLoader, tenantTheme, isSuperUser, embeddingData } = useContext(AppContext);
    const isAuthenticated = useIsAuthenticated();
    const [ticketDetails, setTicketDetails] = React.useState<Tickets[]>([]);
    const [filteredTicketDetails, setFilteredTicketDetails] = React.useState<Tickets[]>([]);
    const [dropDownDetails, setDropdownDetails] = React.useState<Dropdowns[]>([]);
    const account = useAccount(accounts[0] || {});
    const [requestCreationModalOpen, setRequestCreationModalOpen] = React.useState(false);
    const [currentRequestId, setCurrentRequestId] = React.useState<number>(0);
    const [currentRequestDetails, setCurrentRequestDetails] = React.useState<Tickets>(null);
    const [selectedAssignedViewFilter, setSelectedAssignedViewFilter] = React.useState<string>('myOpenRequests');

    const container2Styles = {
        backgroundColor: 'white',
        borderRadius: '6px',
        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
        marginBottom: '15px',
        borderLeft: '4px solid ' + (tenantTheme?.Color5 || '#00ffa9')
    }

    const fetchData = async () => {
        try {
            setDisplayMainLoader(true);

            const response = await AppOwnsDataWebApi.GetTickets(account.username);
            const dropdownRes = await AppOwnsDataWebApi.GetDropdowns();

            setTicketDetails(response || []);

            let doneStatusID = dropdownRes?.find(x => x?.statusName.toUpperCase() === "DONE")?.statusID;
            if (doneStatusID)
                setFilteredTicketDetails(response?.filter(x => x?.createdById === account.username && x?.statusId !== doneStatusID));
            else {
                toast.error("An Unexpected Error Occured");
                console.error("An Error Occurred while loading Ticket Status Values");
            }

            setDropdownDetails(dropdownRes || []);

            setDisplayMainLoader(false);
            setPending(false);
        } catch (e) {
            setPending(false);
            setDisplayMainLoader(false);
            toast.error("An Unexpected Error Occurred");
            console.error(e);
        }
    };

    useEffect(() => {
        if (!isAuthenticated)
            return;

        fetchData();
    }, [])

    useEffect(() => {
        try {
            let doneStatusID = dropDownDetails?.find(x => x?.statusName.toUpperCase() === "DONE")?.statusID;
            if (selectedAssignedViewFilter === 'myOpenRequests') {
                setFilteredTicketDetails(ticketDetails?.filter(x => x.createdById === account.username && x.statusId !== doneStatusID));
            }
            else if (selectedAssignedViewFilter === 'myCompletedRequests') {
                setFilteredTicketDetails(ticketDetails?.filter(x => x.createdById === account.username && x.statusId === doneStatusID));
            }
            else if (selectedAssignedViewFilter === 'allMyRequests') {
                setFilteredTicketDetails(ticketDetails?.filter(x => x.createdById === account.username));
            }
            else if (selectedAssignedViewFilter === 'allRequests') {
                setFilteredTicketDetails(ticketDetails);
            }
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }, [selectedAssignedViewFilter])

    const toggleApprovalNeeded = async (requestId: number) => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.ToggleIsApprovalNeeded(account.username, requestId);
            if (response === true) {
                await fetchData();
            }
        } catch (e) {
            setDisplayMainLoader(false);
            console.error(e);
            toast.error("An Unexpected Error Occurred");
        }
    }

    const supportTicketsColumns = [
        {
            name: 'VIEW/EDIT',
            button: true,
            cell: (row: Tickets) => {
                return (
                    <>
                        {
                            <EditIcon sx={{
                                color: (tenantTheme?.Color1 || '#0d4557'),
                                cursor: 'pointer'
                            }} onClick={() => {
                                setCurrentRequestId(row.id);
                                setCurrentRequestDetails(ticketDetails?.find(x => x.id === row.id));
                                setRequestCreationModalOpen(true);
                            }} />
                        }
                    </>
                )
            }
        },
        {
            name: 'TITLE',
            minWidth: "250px",
            selector: (row: Tickets) => row.title
        },
        {
            name: 'DESCRIPTION',
            minWidth: "300px",
            selector: (row: Tickets) => row.description
        },
        {
            name: 'PRIORITY',
            center: true,
            minWidth: "250px",
            cell: (row: Tickets) => {
                let color = "black";
                let backGroundColor = "grey";
                let statusName = dropDownDetails?.find(x => x.statusID == row?.priorityId)?.statusName || '';

                switch (statusName.toUpperCase()) {
                    case "LOW":
                        backGroundColor = 'green';
                        color = 'white';
                        break;
                    case "MEDIUM":
                        backGroundColor = 'orange';
                        color = 'black';
                        break;
                    case "HIGH":
                        backGroundColor = 'red';
                        color = 'white';
                        break;
                    default:
                }

                return <Chip
                    label={statusName}
                    sx={{
                        color: color,
                        backgroundColor: backGroundColor,
                        width: '50%',
                        border: "none",
                        borderTopRightRadius: '6px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px',
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}
                />
            }
        },
        {
            name: (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '4px', marginBottom: '2px' }} />CREATED ON
                </span>
            ),
            width: "150px",
            minWidth: "150px",
            center: true,
            cell: (row: Tickets) => (<div>{formatDate(row.created)}</div>)
        },
        {
            name: 'CREATED BY',
            minWidth: "252px",
            cell: (row: Tickets) => (
                <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'lighter' }}>
                    <AccountCircleIcon fontSize="small" style={{ margin: '10px 10px 10px 0px', color: '#018b8b' }} />  {row.createdById}
                </span>)
        },
        {
            name: (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '4px', marginBottom: '2px' }} />MODIFIED ON
                </span>
            ),
            center: true,
            width: "150px",
            minWidth: "150px",
            cell: (row: Tickets) => (<div>{row.lastModified ? formatDate(row.lastModified) : '-'}</div>)
        },
        {
            name: 'MODIFIED BY',
            minWidth: "252px",
            cell: (row: Tickets) => (
                <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'lighter' }}>
                    <AccountCircleIcon fontSize="small" style={{ margin: '10px 10px 10px 0px', color: '#018b8b' }} />  {row.lastModifiedById}
                </span>)
        },
        {
            name: 'REPORT REFERENCE',
            minWidth: "150px",
            selector: (row: Tickets) => row.reportReference
        },
        {
            name: 'REPORT PAGE REFERENCE',
            minWidth: "230px",
            selector: (row: Tickets) => row.reportPageReference
        },
        {
            name: 'ASSIGNED TO',
            minWidth: "252px",
            cell: (row: Tickets) => (
                row.assignedToId ?
                    <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'lighter' }}>
                        <AccountCircleIcon fontSize="small" style={{ margin: '10px 10px 10px 0px', color: '#018b8b' }} /> {row.assignedToId}
                    </span> : null)
        },
        {
            name: 'CUSTOMER APPROVER',
            minWidth: "252px",
            cell: (row: Tickets) => (
                row.customerApproverId ?
                    <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'lighter' }}>
                        <AccountCircleIcon fontSize="small" style={{ margin: '10px 10px 10px 0px', color: '#018b8b' }} /> {row.customerApproverId}
                    </span> : null)
        },
        {
            name: 'APPROVAL NEEDED',
            width: "150px",
            minwidth: "150px",
            center: true,
            cell: (row: Tickets) => (
                <Checkbox checked={row.approvalNeeded === "YES" ? true : false}
                    style={{ color: '#0d4557' }} disabled={isSuperUser === true ? false : true}
                    onClick={() => { toggleApprovalNeeded(row.id); }}
                />
            )
        },
        {
            name: 'APPROVAL STATUS',
            width: "250px",
            minwidth: "250px",
            center: true,
            cell: (row: Tickets) => {
                return dropDownDetails?.find(x => x.statusID == row?.approvalStatusId)?.statusName ?
                    <Chip
                        label={dropDownDetails?.find(x => x.statusID == row?.approvalStatusId)?.statusName}
                        sx={{
                            border: "none",
                            borderTopRightRadius: '6px',
                            borderTopLeftRadius: '6px',
                            borderBottomLeftRadius: '6px',
                            borderBottomRightRadius: '6px',
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                        }}
                    /> : <>{''}</>
            }
        },
        {
            name: 'REQUEST TYPE',
            width: "250px",
            minwidth: "250px",
            center: true,
            cell: (row: Tickets) => {
                let color = "white";
                let backGroundColor = "#0d4557";
                let requestTypeName = dropDownDetails?.find(x => x.statusID == row?.requestTypeId)?.statusName || '';

                switch (requestTypeName.toUpperCase()) {
                    case "BUG":
                        backGroundColor = 'firebrick';
                        color = 'white';
                        break;
                    default:
                }

                return <Chip
                    label={requestTypeName}
                    sx={{
                        color: color,
                        backgroundColor: backGroundColor,
                        border: "none",
                        borderTopRightRadius: '6px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px',
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}
                />
            }
        },
        {
            name: (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '4px', marginBottom: '2px' }} />PLANNED DUE
                </span>
            ),
            width: "150px",
            minWidth: "150px",
            center: true,
            cell: (row: Tickets) => (<div>{row.plannedDueDateDBIZ ? formatDate(row.plannedDueDateDBIZ) : '-'}</div>)
        },
        {
            name: 'STATUS',
            width: "250px",
            minwidth: "250px",
            center: true,
            cell: (row: Tickets) => {
                let color = "black";
                let backGroundColor = "grey";
                let statusName = dropDownDetails?.find(x => x.statusID == row?.statusId)?.statusName || '';

                switch (statusName.toUpperCase()) {
                    case "NEW":
                        backGroundColor = 'gold';
                        color = 'black';
                        break;
                    case "DONE":
                        backGroundColor = 'forestgreen';
                        color = 'white';
                        break;
                    default:
                }

                return <Chip
                    label={statusName}
                    sx={{
                        backgroundColor: backGroundColor,
                        color: color,
                        border: "none",
                        borderTopRightRadius: '6px',
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px',
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}
                />
            }
        },
        {
            name: (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarMonthIcon fontSize="small" style={{ marginRight: '4px', marginBottom: '2px' }} />COMPLETED ON
                </span>
            ),
            width: "150px",
            minWidth: "150px",
            center: true,
            cell: (row: Tickets) => (<div>{row.completedDate ? formatDate(row.completedDate) : '-'}</div>)
        },
        {
            name: 'COMPLETED BY',
            minWidth: "252px",
            cell: (row: Tickets) => (
                row.completedById ?
                    <span style={{ display: 'flex', alignItems: 'center', fontWeight: 'lighter' }}>
                        <AccountCircleIcon fontSize="small" style={{ margin: '10px 10px 10px 0px', color: '#018b8b' }} /> {row.completedById || ''}
                    </span> : null)
        },
        {
            name: 'STATUS NOTE (CUSTOMER)',
            minWidth: "230px",
            selector: (row: Tickets) => row.statusNoteCustomer
        },
        {
            name: 'STATUS NOTE (DBIZ)',
            minWidth: "230px",
            selector: (row: Tickets) => row.statusNoteDBIZ
        },
        
    ];

    if (!isAuthenticated) {
        return <Unauthenticated />;
    }

    if (isAuthenticated) {
        return (
            <>
                <Container maxWidth={false}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        height: '70%'
                    }}>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3, ease: 'easeInOut' }}
                        >
                            <Typography variant='h5' component="h2" sx={{ mt: 2, mb: "6px", textAlign: 'center', fontWeight: '500px' }} >USER REQUESTS</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <>
                                        <Button
                                            startIcon={<AddIcon />}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                float: 'right',
                                                marginBottom: "4px",
                                                color: tenantTheme?.Color4 || '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                                    color: tenantTheme?.Color4 || '#FFFFFF',
                                                },
                                            }}
                                            onClick={() => {
                                                setCurrentRequestId(0);
                                                setCurrentRequestDetails(null);
                                                setRequestCreationModalOpen(true);
                                            }}
                                        >
                                            New Request
                                        </Button>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                            <IconButton sx={{
                                                color: tenantTheme?.Color1 || '#0d4557',
                                            }} onClick={() => {
                                                navigate("/reports/" + embeddingData?.pageLoadReportId);
                                            }}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                Back to Reports
                                            </Typography>
                                        </Box>
                                    </>
                                    <DataTable
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    color: tenantTheme?.Color3 || '#FFFFFF',
                                                    backgroundColor: tenantTheme?.Color1 || '#0d4557'
                                                },
                                            },
                                        }}
                                        columns={supportTicketsColumns}
                                        data={filteredTicketDetails}
                                        pagination
                                        progressPending={pending}
                                        progressComponent={<CustomLoader />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={mainContainerGridStyles}>
                                    <Container sx={container1Styles}>
                                        {requestCreationModalOpen === false && (
                                            <>
                                                <Box sx={box1Styles}>
                                                    <FilterAltIcon sx={filterIconStyles} />
                                                    <Typography variant="h6" sx={applyFilterTextStyles}>APPLY FILTERS</Typography>
                                                </Box>
                                                <Container
                                                    sx={container2Styles}>
                                                    <Box sx={box1Styles}>
                                                        <NumbersIcon sx={filtersIconStyles} />
                                                        <Typography variant="h6" sx={filtersTypoGraphyStyles}>
                                                            My Active Requests : {ticketDetails?.filter(x => x.createdById === account.username && x.statusId !== 8)?.length || 0}
                                                        </Typography>
                                                    </Box>
                                                </Container>
                                                <Container
                                                    sx={container2Styles}>
                                                    <Box sx={box3Styles}>
                                                        <Select
                                                            labelId="currenentRequests-label"
                                                            id="currenentRequests"
                                                            value={selectedAssignedViewFilter}
                                                            input={<Input />}
                                                            sx={searchInputs2Styles}
                                                            onChange={(e) => { setSelectedAssignedViewFilter(e.target.value) }}
                                                        >
                                                            <MenuItem key="myOpenRequests" value="myOpenRequests">My Active Requests</MenuItem>
                                                            <MenuItem key="myCompletedRequests" value="myCompletedRequests">My Completed Requests</MenuItem>
                                                            <MenuItem key="allMyRequests" value="allMyRequests">All My Requests</MenuItem>
                                                            <MenuItem key="allRequests" value="allRequests">All Requests</MenuItem>
                                                        </Select>
                                                    </Box>
                                                </Container>
                                            </>)
                                        }
                                        {requestCreationModalOpen === true && (
                                            <TicketView
                                                currentRequestId={currentRequestId}
                                                setRequestCreationModalOpen={setRequestCreationModalOpen}
                                                currentRequestDetails={currentRequestDetails}
                                                dropDownDetails={dropDownDetails}
                                                fetchData={fetchData}
                                                allowUpdation={() => currentRequestDetails?.createdById === account.username || isSuperUser}
                                                screenType="UserRequestUi"
                                            />
                                        )}
                                    </Container>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </AnimatePresence>
                </Container>
            </>
        )
    }
};

export default UserRequest;