
import { createContext, Dispatch, SetStateAction } from 'react';

import { PowerBiDataset, PowerBiReport } from './models/models';

export interface EmbeddingData {
    tenantName: string;
    reports: PowerBiReport[];
    pageLoadReportId: string;
    datasets: PowerBiDataset[];
    user: string;
    userCanEdit: boolean;
    userCanCreate: boolean;
    workspaceArtifactsLoading?: boolean;
    redirectUserToReport: boolean,
    setRedirectUserToReport: Dispatch<SetStateAction<boolean>>,
    reportLanguageCode: string;
    setReportLanguageCode: Dispatch<SetStateAction<string>>,
    isRequestModuleEnabled: boolean
}

export const EmbeddingDataDefaults: EmbeddingData = {
    tenantName: null,
    reports: [],
    pageLoadReportId: '',
    datasets: [],
    user: null,
    userCanEdit: null,
    userCanCreate: null,
    workspaceArtifactsLoading: false,
    redirectUserToReport: false,
    setRedirectUserToReport: null,
    reportLanguageCode: 'en-US',
    setReportLanguageCode: null,
    isRequestModuleEnabled: false
}

export interface AppContextProps {
    embeddingData: EmbeddingData;
    refreshEmbeddingData: () => void;
    setDisplayMainLoader: Dispatch<SetStateAction<boolean>>;
    displayMainLoader: boolean,
    isSuperUser: boolean,
    isDbizAdmin: boolean,
    tenantLogo: any,
    tenantTheme: any,
    reportBackgroundColor: string
}

export const AppContext = createContext<AppContextProps>({
    embeddingData: EmbeddingDataDefaults,
    refreshEmbeddingData: () => { },
    setDisplayMainLoader: () => { },
    displayMainLoader: false,
    isSuperUser: false,
    isDbizAdmin: false,
    tenantLogo: null,
    tenantTheme: null,
    reportBackgroundColor: null
});


