import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Container, Dialog, DialogContent, Grid, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { toast } from 'react-toastify';
import { AppContext } from '../AppContext';
import AppOwnsDataWebApi from '../services/AppOwnsDataWebApi';
import InfoIcon from '@mui/icons-material/Info';

interface ColorPickerProps {
    initialColor: string;
    onSelectColor: (color: string, colorKey: string) => void;
    colorKey: string;
}

const CollapsibleColorPicker: React.FC<ColorPickerProps> = ({ initialColor, onSelectColor, colorKey }) => {
    const [selectedColor, setSelectedColor] = useState<string>(initialColor);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleColorChange = (color: ColorResult) => {
        setSelectedColor(color.hex);
        onSelectColor(color.hex, colorKey);
    };
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div key={colorKey}>
            <Button
                onClick={handleOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: 'auto',
                }}
            >
                <div
                    key={"div" + { colorKey }}
                    style={{
                        width: '30px',
                        height: '30px',
                        backgroundColor: selectedColor,
                        border: '3px solid #000',
                    }}
                />
            </Button>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogContent sx={{ padding: '0px' }}>
                    <SketchPicker color={selectedColor} onChange={handleColorChange} disableAlpha />
                </DialogContent>
            </Dialog>
        </div>
    );
};

interface ThemeSelectionProps {
    tenantName: string;
    getTenantsIndicators: () => void;
    handleThemesTabClick: () => void;
    manageTenantTheme: any;
    oneOffThemes: any;
}

interface ColorMessages {
    [key: string]: string;
}

const ThemeSelection = ({
    oneOffThemes,
    tenantName,
    getTenantsIndicators,
    manageTenantTheme,
}: ThemeSelectionProps) => {
    const { setDisplayMainLoader } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState<string>(oneOffThemes?.reportBackgroundColor || '#EEEEEE');
    const handleColorChange = (color: ColorResult) => {
        setSelectedColor(color.hex);
    };

    const colors = [
        "Color1",
        "Color2",
        "Color3",
        "Color4",
        "Color5",
        "Color6"
    ];

    const [colorsToSave, setColorsToSave] = useState<any>({
        Color1: "",
        Color2: "",
        Color3: "",
        Color4: "",
        Color5: "",
        Color6: "",
    });

    const colorMessage: ColorMessages = {
        Color1: "This affects the background color of the Login/Logout button, the background color of the left Reports navigation list, background color of the table headers on User & DBIZ Management Screen, "
            + "and the background color of the buttons like 'Apply Filters'.",
        Color2: "This affects the background color of buttons like 'Clear Filters', 'Update Roles' on the User & DBIZ Management Screen.",
        Color3: "This affects the text color of the buttons like 'Apply Filters' & text color of table headers on the User & DBIZ Management Screen.",
        Color4: "This affects the text color of the buttons like 'Update Roles', 'Clear Filters' on the User & DBIZ Management Screen.",
        Color5: "This affects the text color of the Login/Logout button and the Icon colors on the left Reports navigation list.",
        Color6: "This affects the background color of the selected menu item in the left Reports navigation list.",
    };

    useEffect(() => {

        if (manageTenantTheme) {
            setColorsToSave(manageTenantTheme);
        }

    }, [manageTenantTheme]);

    const handleThemeRestClick = async () => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.ResetTheme(tenantName);
            if (response == true) {
                await getTenantsIndicators();
                toast.success("Theme Reset Successfully!");
            }

            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    const handleSaveTheme = async () => {
        try {
            setDisplayMainLoader(true);

            if (Object.values(colorsToSave).some((color) => color === "")) {
                toast.warning("Please select all colors before saving.");
                setDisplayMainLoader(false);
                return;
            }
            const themeInfo = {
                ThemeVersion: "1",
                ThemeInfo: JSON.stringify(colorsToSave, null, 0)
            }

            const response = await AppOwnsDataWebApi.SaveTheme(tenantName, themeInfo);
            if (response == true) {
                await getTenantsIndicators();
                toast.success("Theme Saved Successfully!");
            }

            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    };


    const handleOneOffThemeRestClick = async () => {
        try {
            setDisplayMainLoader(true);
            const response = await AppOwnsDataWebApi.ResetOneOffTheme(tenantName);
            if (response == true) {
                await getTenantsIndicators();
                setSelectedColor('#EEEEEE');
                toast.success("Reset Successfully!");
            }

            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    }

    const handleSaveOnOffThemes = async () => {
        try {
            setDisplayMainLoader(true);

            const onOffThemeInfo = {
                reportBackGroundColor: selectedColor
            }

            const response = await AppOwnsDataWebApi.SaveOneOffTheme(tenantName, onOffThemeInfo);
            if (response == true) {
                await getTenantsIndicators();
                toast.success("Saved Successfully!");
            }

            setDisplayMainLoader(false);
        } catch (e) {
            setDisplayMainLoader(false);
        }
    };


    const handleColorSelect = (color: string, colorKey: string) => {
        setColorsToSave({
            ...colorsToSave,
            [colorKey]: color
        });
    };

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div style={{ padding: '20px', marginBottom: '25px' }}>
                <Grid container item key={1} spacing={2}>
                    {colors.map((colorKey, col) => (
                        <Grid item key={col} xs={4}>
                            <Container
                                sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '6px',
                                    boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                                    marginBottom: '15px',
                                    borderLeft: '4px solid #00ffa9',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Tooltip title={colorMessage?.[colorKey]}>
                                        <InfoIcon
                                            sx={{
                                                verticalAlign: 'bottom',
                                                marginRight: '10px',
                                                color: 'slategrey',
                                                padding: '1px',
                                            }}
                                        />
                                    </Tooltip>
                                    <div style={{
                                        marginLeft: '0px',
                                        marginRight: 'auto',
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                        fontWeight: '500'
                                    }}>
                                        Select Color #{col + 1}</div>
                                    <CollapsibleColorPicker
                                        initialColor={manageTenantTheme?.[colorKey] || '#FFFFFF'}
                                        onSelectColor={handleColorSelect}
                                        colorKey={colorKey}
                                        key={col + 1}
                                    />
                                </Box>
                            </Container>
                        </Grid>
                    ))}
                </Grid>
                <Button
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                        backgroundColor: manageTenantTheme?.Color1 || '#0d4557',
                        color: manageTenantTheme?.Color3 || '#FFFFFF',
                        '&:hover': {
                            backgroundColor: manageTenantTheme?.Color1 || '#0d4557',
                        },
                        float: 'right',
                        marginBottom: "4px",
                    }}
                    onClick={() => { handleSaveTheme() }}>
                    Save Theme
                </Button>
                <Button
                    startIcon={<ReplayIcon />}
                    variant="contained"
                    onClick={() => { handleThemeRestClick(); }}
                    sx={{
                        backgroundColor: manageTenantTheme?.Color2 || '#018b8b',
                        color: manageTenantTheme?.Color4 || '#FFFFFF',
                        '&:hover': {
                            backgroundColor: manageTenantTheme?.Color2 || '#018b8b',
                        },
                        float: 'right',
                        marginBottom: "4px",
                        marginRight: "4px",
                    }}
                >
                    Reset To Default
                </Button>
            </div>

            <p style={{ marginTop: 0, display: 'inline' }}>Element Specific Theme: Customized styles tailored to individual UI elements for fine-tuned appearance.</p>
            <div style={{ padding: '20px', marginBottom: '25px' }}>
                <Container
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '6px',
                        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                        margin: '0px',
                        width: '32.33%',
                        borderLeft: '4px solid #00ffa9',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                        <Tooltip title="Set Color of Report Background">
                            <InfoIcon
                                sx={{
                                    verticalAlign: 'bottom',
                                    marginRight: '10px',
                                    color: 'slategrey',
                                    padding: '1px',
                                }}
                            />
                        </Tooltip>
                        <div style={{
                            marginLeft: '0px',
                            marginRight: 'auto',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            fontWeight: '500'
                        }}>
                            Report Background Color</div>
                        <Button
                            onClick={handleOpen}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                margin: '0',
                            }}
                        >
                            <div
                                key={"div_ReportBackgroundColor"}
                                style={{
                                    width: '30px',
                                    margin: '0px',
                                    height: '30px',
                                    backgroundColor: selectedColor,
                                    border: '3px solid #000',
                                }}
                            />
                        </Button>
                        <Dialog open={isOpen} onClose={handleClose}>
                            <DialogContent sx={{ padding: '0px' }}>
                                <SketchPicker color={selectedColor} onChange={handleColorChange} disableAlpha />
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Container>
                <Button
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                        backgroundColor: manageTenantTheme?.Color1 || '#0d4557',
                        color: manageTenantTheme?.Color3 || '#FFFFFF',
                        '&:hover': {
                            backgroundColor: manageTenantTheme?.Color1 || '#0d4557',
                        },
                        float: 'right',
                        marginBottom: "4px",
                    }}
                    onClick={() => { handleSaveOnOffThemes() }}>
                    Save Theme
                </Button>
                <Button
                    startIcon={<ReplayIcon />}
                    variant="contained"
                    onClick={() => { handleOneOffThemeRestClick(); }}
                    sx={{
                        backgroundColor: manageTenantTheme?.Color2 || '#018b8b',
                        color: manageTenantTheme?.Color4 || '#FFFFFF',
                        '&:hover': {
                            backgroundColor: manageTenantTheme?.Color2 || '#018b8b',
                        },
                        float: 'right',
                        marginBottom: "4px",
                        marginRight: "4px",
                    }}
                >
                    Reset To Default
                </Button>
            </div>
        </>
    );
};

export default ThemeSelection;
