import { useIsAuthenticated } from "@azure/msal-react";
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Login from './LoginMenu';

const bannerBoxStyles = {
    display: 'flex',
    height: '100%'
}

const tenantLogoParentStyles = {
    paddingLeft: '7px',
    paddingTop: '4px'
}

const progressIndicatorBoxStyles = {
    width: '100%'
}

const AnimatedMenuIcon = motion(MenuIcon);

interface BannerProps {
    setLeftNavExpanded: (LeftNavExpanded: boolean) => void;
}

const Banner = ({ setLeftNavExpanded }: BannerProps) => {
    let navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { embeddingData, displayMainLoader, tenantLogo, tenantTheme } = useContext(AppContext);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const appBarStyles = isAuthenticated ? {
        backgroundColor: tenantTheme?.Color3 || "#FFFFFF",
        zIndex: 2,
        height: "52px",
        p: 0
    } : {
        backgroundColor: "#FFFFFF",
        zIndex: 2,
        height: "52px",
        p: 0
    }

    const loaderStyles = {
        backgroundColor: tenantTheme?.Color1 || "#0d4557",
    };

    const menuIconStyles = isAuthenticated ? {
        color: tenantTheme?.Color1 || '#0d4557',
        height: "100%",
        ml: "9px",
        mr: "9px",
        cursor: 'pointer',
        outline: 'none'
    } : {
        color: '#0d4557',
        height: "100%",
        ml: "9px",
        mr: "9px",
        cursor: 'pointer',
        outline: 'none'
    }

    return (
        <>
            <AppBar position="relative" sx={appBarStyles}>
                <Box sx={bannerBoxStyles}>
                    {
                        isAuthenticated && !isMobile && (
                            <AnimatedMenuIcon
                                whileHover={{ scale: 1.3 }}
                                whileTap={{ scale: 0.9 }}
                                sx={menuIconStyles}
                                onClick={() => { setLeftNavExpanded(true) }} />
                        )
                    }
                    <div style={tenantLogoParentStyles}>
                        {!isAuthenticated &&
                            <img src="https://dbiz.euwest01.umbraco.io/media/ux1pzwlh/dbiz-logo-rgb.svg" alt="DBIZ" width="92" height="42" aria-label="menu" />
                        }
                        {isAuthenticated && tenantLogo &&
                            <img src={tenantLogo} alt="DBIZ" style={{ objectFit: "contain" }} width="92" height="42" onClick={() => {
                                if (embeddingData.redirectUserToReport === true) {
                                    if (embeddingData.reports.length > 0) {
                                        navigate("/reports/" + embeddingData.pageLoadReportId);
                                    }
                                }
                            }} aria-label="menu" />
                        }
                    </div>
                    <Login />
                </Box>
                {displayMainLoader === true && (<Box sx={progressIndicatorBoxStyles}>
                    <LinearProgress sx={loaderStyles} />
                </Box>)}
            </AppBar>
        </>
    )
}

export default Banner;