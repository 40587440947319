import { Button, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useContext } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../AppContext';
import { ManageUserDetails } from '../models/models';
import AppOwnsDataWebApi from '../services/AppOwnsDataWebApi';


interface UserCreationProps {
    userCreationModalOpen: boolean;
    setUserCreationModalOpen: Dispatch<SetStateAction<boolean>>;
    setEmail: Dispatch<SetStateAction<string>>;
    email: string;
    setUserName: Dispatch<SetStateAction<string>>;
    userName: string;
    setShowValidationMessage: Dispatch<SetStateAction<boolean>>;
    showValidationMessage: boolean;
    setMangeUserDetails: Dispatch<SetStateAction<ManageUserDetails[]>>;
    setTenantUserDetails: Dispatch<SetStateAction<ManageUserDetails[]>>;
    loggedInUser: string;
    callingScreen: string;
}

const UserCreation = ({
    userCreationModalOpen,
    setUserCreationModalOpen,
    setEmail,
    email,
    setUserName,
    userName,
    setShowValidationMessage,
    showValidationMessage,
    setMangeUserDetails,
    setTenantUserDetails,
    loggedInUser,
    callingScreen
}: UserCreationProps) => {

    const { setDisplayMainLoader, tenantTheme } = useContext(AppContext);
    const handleNewUserCreationClickClose = () => {
        setUserCreationModalOpen(false);
        setUserName('');
        setEmail('');
    };

    const handleNewUserCreation = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email) || userName === '') {
            setShowValidationMessage(true);
            return;
        }

        try {
            setShowValidationMessage(false);
            setDisplayMainLoader(true);
            let response;
            if (callingScreen === "UserManagement") {
                response = await AppOwnsDataWebApi.CreateNewUser(email, userName, loggedInUser, true);
            }
            else if (callingScreen === "DBIZManagement") {
                response = await AppOwnsDataWebApi.CreateNewUser(email, userName, loggedInUser, false);
            }

            if (response === "USER CREATED") {
                setUserCreationModalOpen(false);
                if (callingScreen === "UserManagement") {
                    const response = await toast.promise(
                        AppOwnsDataWebApi.GetUsers(loggedInUser),
                        {
                            success: 'New User Added',
                            error: 'An Error Occured'
                        }
                    );
                    setMangeUserDetails([...response.users]);
                }
                else if (callingScreen === "DBIZManagement") {
                    const response = await toast.promise(
                        AppOwnsDataWebApi.GetUsers(),
                        {
                            success: 'New User Added',
                            error: 'An Error Occured'
                        }
                    );
                    setTenantUserDetails([...response.users]);
                }

                setUserCreationModalOpen(false);
            }
            else if (response === "USER ALREADY EXISTS") {
                setUserCreationModalOpen(false);
                toast.warning("This Username/Login ID has already been taken!");
            }
            else {
                setUserCreationModalOpen(false);
                toast.error("Something Went Wrong!");
            }
            setDisplayMainLoader(false);
        } catch (error) {
            setDisplayMainLoader(false);
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                <Dialog
                    open={userCreationModalOpen}
                    onClose={handleNewUserCreationClickClose}>
                    <DialogTitle>CREATE NEW USER</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the below details to create a New User.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="loginId"
                            label="Login ID"
                            type="email"
                            fullWidth
                            variant="standard"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="User Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            required
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        {showValidationMessage &&
                            <>
                                <AnimatePresence>
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.3, ease: 'easeIn' }}
                                    ><Alert severity="error">Please make sure that the values are entered correctly!</Alert>
                                    </motion.div>
                                </AnimatePresence>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                color: tenantTheme?.Color4 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color2 || '#018b8b',
                                },
                            }}
                            onClick={handleNewUserCreationClickClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                color: tenantTheme?.Color3 || '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: tenantTheme?.Color1 || '#0d4557',
                                },
                            }}
                            onClick={handleNewUserCreation}>Create</Button>
                    </DialogActions>
                </Dialog>
            </motion.div>
        </AnimatePresence>
    );
}

export default UserCreation;