import update from 'immutability-helper';
import { useCallback } from 'react';
import { DraggableReportCard } from '../components/DraggableReportCard';
import { Reports } from '../models/models';

interface ReportContainerProps {
    setAllReportsToReorder: React.Dispatch<React.SetStateAction<Reports[]>>;
    allReportsToReorder: Reports[];
}

export const ReportContainer: React.FC<ReportContainerProps> = ({ allReportsToReorder, setAllReportsToReorder }) => {
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setAllReportsToReorder((prevCards: Reports[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Reports],
                ],
            }),
        )
    }, [])

    const renderCard = useCallback(
        (card: Reports, index: number) => {
            return (
                <DraggableReportCard
                    key={card.reportID}
                    index={index}
                    id={card.reportID}
                    text={card.reportName}
                    moveCard={moveCard}
                    totalRecordCount={allReportsToReorder?.length || 0}
                />
            )
        },
        [],
    )

    return (
        <div style={{
            width: '100%'
        }}>
            {allReportsToReorder.map((card, i) => renderCard(card, i))}
        </div>
    )
}

