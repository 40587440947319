import {
    Container,
    Paper, Typography
} from '@mui/material';


const containerStyles = {
    color: 'white',
    backgroundColor: '#0d4557',
    padding: 2,
    textAlign: 'center',
    margin: '7px',
    boxShadow: '0 15px 14px rgb(0 0 0 / 0.3)'
}

const typoGraphy1Styles = {
    marginBottom: 1,
    textAlign: 'center',
    paddingTop: '4%',
    fontWeight: 'bolder'
}

const highlightStyles = {
    color: '#30dea3',
    fontWeight: "600"
}

const typoGraphy2Styles = {
    textAlign: 'center',
    paddingBottom: '4%'
}

const Unauthenticated = () => {
    return (
        <>
            <Container maxWidth={false}>
                <Paper sx={containerStyles}>
                    <Typography variant="h4" component="h1" sx={typoGraphy1Styles}>
                        Welcome to <span style={highlightStyles}>DBIZ</span> Portal
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={typoGraphy2Styles}
                    >
                        Click on the login link in the upper right corner to get started.
                    </Typography>
                </Paper>
            </Container>
        </>
    )
}

export default Unauthenticated;