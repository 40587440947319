import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface CardProps {
    id: any
    text: string
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    totalRecordCount: number
}

interface DragItem {
    index: number
    id: string
    type: string
}


export const ItemTypes = {
    CARD: 'card',
}

export const DraggableReportCard: FC<CardProps> = ({ id, text, index, moveCard, totalRecordCount }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isFirstRow = index === 0;
    const isLastRow = index === totalRecordCount - 1;
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            const clientOffset = monitor.getClientOffset()

            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveCard(dragIndex, hoverIndex)

            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const style = {
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'white',
        cursor: 'move',
        borderRadius: '3px',
        fontWeight: '400',
        fontSize: '14px'
    }

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            {text}
            <span style={{ float: 'right' }}>
                {!isFirstRow &&
                    <ArrowUpwardIcon
                        style={{ cursor: 'pointer', marginRight: '5px' }}
                        onClick={() => moveCard(index, index - 1)}
                    />
                }
                {!isLastRow &&
                    <ArrowDownwardIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => moveCard(index, index + 1)}
                    />
                }
                <DragIndicatorIcon style={{ cursor: 'move' }} />
            </span>
        </div>
    )
}
