import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const typoGraphy1Styles = {
    my: 3
}

const alertStyles = {
    border: 1, padding: 2, mx: 2
}

const homeButtonStyles = {
    mt: 3
}

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth={false}>
            <Typography variant='h5' component="h2" sx={typoGraphy1Styles} >The request page cannot be found</Typography>
            <Alert sx={alertStyles} severity="error"> The following URL is not valid: <strong>{document.URL}</strong></Alert>
            <Button onClick={() => { navigate("/"); }} sx={homeButtonStyles} >
                Go to home page
            </Button>
        </Container>
    )
};

export default PageNotFound;