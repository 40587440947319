
export class PowerBiReport {
    id: string;
    name: string;
    datasetId: string;
    embedUrl: string;
    reportType: string;
}

export class PowerBiDataset {
    id: string;
    name: string;
    createReportEmbedURL: string;
}

class PowerBiTenant {
    name: string;
}

export class ViewModel {
    tenantName: string;
    reports: PowerBiReport[];
    datasets: PowerBiDataset[];
    embedToken: string;
    embedTokenExpiration: string;
    user: string;
    userCanEdit: boolean;
    userCanCreate: boolean;
    isSuperUser: boolean;
    isDbizAdmin: boolean;
    tenantReportSortOrder: ReportSorting[];
    tenantDefaultId: string;
    tenantThemeData: string;
    reportLanguageCode: string;
    isRequestModuleEnabled: boolean;
    reportBackgroundColor: string
}

export class EmbedTokenResult {
    token: string;
    expiration: string;
}

export class ActivityLogEntry {
    LoginId: string;
    User: string;
    Activity: string;
    Tenant: string;
    Dataset: string;
    DatasetId: string;
    Report: string;
    ReportId: string;
    ReportType: string;
    PageName: string;
    OriginalReportId: string;
    LoadDuration: number;
    RenderDuration: number;
    CorrelationId: string;
    EmbedTokenId: string;
}

export class User {
    LoginId: string;
    UserName: string;
    isSuperUser: boolean;
    isDbizAdmin: boolean;
}

export class Reports {
    reportID: string;
    reportName: string;
    WorkspaceID: string;
    WorkspaceName: string;
    CreatedOn?: Date;
    CreatedBy: string;
    IsActive: boolean;
    isAssigned: boolean = false;
    isDefaultReport: boolean = false;
    assignmentCount: number = 0;
}

export class AssignedReports {
    id?: number
    userID: string;
    reportID: string;
    isDefaultReport: boolean;
}

export class UserReports {
    allReports: Reports[];
    assignedReports: AssignedReports[];
}

export class MangeUsersDTO {
    users: ManageUserDetails[];
    tenantRoles: TenantRoles[]
}

export class MangeUsersDTOV2 {
    users: ManageUserDetails[];
    tenants: PowerBiTenant[];
    tenantRoles: TenantRoles[];
}

export class UserRoles {
    loginId: string;
    role: string;
}

export class PermissionRequest {
    userRoles?: UserRoles[];
    right?: string;
    reports?: string[];
}

export class ValidationPermissionsRequest {
    userToCheck: string;
    permissionsToCheck: string[];
}

export class ManageUserDetails {
    loginId: string;
    userName: string;
    canEdit: boolean;
    canCreate: boolean;
    isSuperUser: boolean | null;
    role: string;
    created: string;
    lastLogin: string;
    tenantName: string;
    reportLanguageCode: string;
}

export class ExportFileRequest {
    ReportId: string;
    ExportType: "PDF" | "PNG" | "PPTX";
    Filter?: string;
    BookmarkState?: string;
    PageName?: string;
    VisualName?: string;
}

export class ReportSorting {
    reportId: string;
    sortOrder: number;
}

export class TenantManagementInfo {
    allReports: Reports[];
    reportSorting: ReportSorting[];
    tenantDefaultReportId: string;
    tenantRoles: TenantRoles[];
}

export class TenantRoles {
    id: number;
    tenantName: string;
    role: string;
}

export class UploadedImage {
    ImageFile: string;
}

export class TenantIndicators {
    name: string;
    isImageUploaded: boolean;
    isThemeConfigured: boolean;
    isTenantDefaultReportSet: boolean;
}

export class Tickets {
    id: number;
    title: string;
    description: string;
    priorityId: number;
    requestTypeId: number;
    reportReference: string;
    reportPageReference: string;
    created: string;
    createdById: string;
    lastModified: string;
    lastModifiedById: string;
    approvalNeeded: string;
    approvalStatusId: number;
    approvalComment: string;
    approvalDate: string;
    hoursEstimatesDBIZ?: number;
    customerApproverId: string;
    assignedToId: string;
    statusNoteCustomer: string;
    statusNoteDBIZ: string;
    notesInternalDBIZ: string;
    plannedDueDateDBIZ?: string;
    requestCostId: number;
    completedDate?: string;
    completedById: string;
    tenant: string;
    statusId: number;
    attachments: attachment[];
}

export class attachment {
    attachmentId: number;
    fileName: string;
}

export class Dropdowns {
    statusID: number;
    statusName: string;
    context: string;
}

export interface MangeTenantUsersRowData {
    loginId: string;
    userName: string;
    lastLogin: string;
    roleId: number;
    canEdit: boolean;
    canCreate: boolean,
    isSuperUser: boolean,
    isDbizAdmin: boolean,
    tenantName: string;
    reportLanguageCode: string;
}

export interface TenantReportCodesRowData {
    Id: string;
    role: string;
}

export interface MangeUsersRowData {
    loginId: string;
    userName: string;
    lastLogin: string;
    roleId: number;
    canEdit: boolean;
    canCreate: boolean,
    isSuperUser: boolean,
    reportLanguageCode: string;
}

export interface MangeReportsRowData {
    workspaceName: string;
    reportID: string;
    reportName: string;
    isActive: boolean;
    isAssigned: boolean;
    isDefaultReport: boolean;
}

export interface ManageReportSortRowData {
    reportName: string;
    reportID: string;
    assignmentCount: number;
    defaultReportId: string;
}
