import { useIsAuthenticated } from "@azure/msal-react";
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../AppContext";
import { PowerBiReport } from '../models/models';
import DataLoading from './DataLoading';
const AnimatedListItem = motion(ListItem);
const AnimatedMenuIcon = motion(MenuIcon);

const leftNavOuterBoxStyles = {
    width: 1,
    display: "flex"
};

const avatarStyles = {
    width: "22px",
    height: "22px",
    m: 0,
    mt: "2px",
    cursor: 'pointer',
    outline: 'none'
};

const leftNavListStyles = {
    m: 0,
    p: 0,
    pb: "8px",
    width: 1
};

const MobileNav = () => {

    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { embeddingData, tenantTheme } = useContext(AppContext);
    const [mobileNavExpanded, setMobileNavExpanded] = useState(false);

    const typoGraphyStyles = {
        cursor: 'pointer',
        color: tenantTheme?.Color3 || '#FFFFFF',
    };

    const leftNavInnerBoxLeftStyles = {
        color: tenantTheme?.Color5 || '#00ffa9',
        width: "48px",
        minWidth: "48px",
        textAlign: "center",
        p: 0,
        pt: "4px",
    };

    const leftNavTenantNameStyles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        fontSize: "18px",
        width: 1,
        pt: "4px"
    };

    const leftNavHeaderStyles = {
        color: tenantTheme?.Color3 || '#FFFFFF',
        fontSize: "18px",
        width: 1,
        fontWeight: "200",
        pl: 0,
        mb: 0,
        my: 0,
        pt: "2px"
    };

    const containerBoxStyles = {
        color: tenantTheme?.Color1 || '#0d4557',
        width: "48px",
        minWidth: "48px",
        textAlign: "center",
        p: 0,
        pt: "4px"
    };

    const paperPropsStyles = {
        backgroundColor: tenantTheme?.Color1 || '#0d4557',
        borderRight: "1px solid #444444",
        width: 240
    };

    const leftNavInnerBoxTenantNameProps: SxProps = {
        pt: "2px",
        pl: "4px",
        width: mobileNavExpanded ? 1 : 0,
        maxHeight: "28px;"
    };

    const leftNavInnerBoxRightProps: SxProps = {
        color: tenantTheme?.Color4 || '#000000',
        py: 0,
        pl: "4px",
        width: mobileNavExpanded ? 1 : 0,
    };

    const divider1Styles = {
        backgroundColor: tenantTheme?.Color3 || '#FFFFFF',
    };

    return (
        <>
            {isAuthenticated &&
                <Box sx={containerBoxStyles} >
                    <AnimatedMenuIcon
                        whileHover={{ scale: 1.3 }}
                        whileTap={{ scale: 0.9 }}
                        sx={avatarStyles}
                        onClick={() => { setMobileNavExpanded(!mobileNavExpanded) }} />
                </Box>
            }
            <Drawer
                variant={'temporary'}
                anchor='left'
                open={mobileNavExpanded}
                onClose={() => { setMobileNavExpanded(false) }}
                PaperProps={{ sx: paperPropsStyles }}
            >
                <Box>
                    <Box sx={leftNavOuterBoxStyles} >
                        <Box sx={leftNavInnerBoxLeftStyles} >
                            <AnimatedMenuIcon
                                whileHover={{ scale: 1.3 }}
                                whileTap={{ scale: 0.9 }}
                                sx={avatarStyles}
                                onClick={() => { setMobileNavExpanded(!mobileNavExpanded) }} />
                        </Box>
                        <Box sx={leftNavInnerBoxTenantNameProps} >
                            <Typography sx={leftNavTenantNameStyles} variant='h5' fontSize={20} >{embeddingData.tenantName}</Typography>
                        </Box>
                    </Box>
                    <Divider sx={divider1Styles} />
                    <Box sx={leftNavOuterBoxStyles} >
                        <Box sx={leftNavInnerBoxLeftStyles} >
                            <AssessmentIcon sx={avatarStyles} />
                        </Box>
                        <AnimatePresence>
                            {mobileNavExpanded &&
                                <Box sx={leftNavInnerBoxRightProps}>
                                    <Typography variant='subtitle1' sx={leftNavHeaderStyles}>View Reports</Typography>
                                    {embeddingData.workspaceArtifactsLoading && <DataLoading />}
                                    {!embeddingData.workspaceArtifactsLoading && (
                                        <List disablePadding dense sx={leftNavListStyles}>
                                            {embeddingData.reports?.map((report: PowerBiReport) => (
                                                <AnimatedListItem
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    whileHover={{ scale: 1.05 }}
                                                    transition={{ duration: 0.3 }}
                                                    key={report.id}
                                                    sx={{
                                                        py: "4px", pl: "6px", width: 1,
                                                        fontWeight: "100",
                                                        fontSize: "14px",
                                                        backgroundColor: (document.URL.includes(report.id)) ? (tenantTheme?.Color6 || '#293b40') : (tenantTheme?.Color1 || '#0d4557'),
                                                        borderLeft: (document.URL.includes(report.id)) ? "4px solid " + (tenantTheme?.Color5 || '#00ffa9') : "",
                                                        ':hover': {
                                                            backgroundColor: (tenantTheme?.Color6 || '#293b40'),
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setMobileNavExpanded(false);
                                                        navigate("/reports/" + report.id);
                                                    }} >
                                                    <Typography variant="inherit" noWrap sx={typoGraphyStyles}>
                                                        {report.name}
                                                    </Typography>
                                                </AnimatedListItem>
                                            ))}
                                        </List>
                                    )}
                                </Box>
                            }
                        </AnimatePresence>
                    </Box>
                    <Divider sx={divider1Styles} />
                </Box>
            </Drawer >
        </>
    )
}

export default MobileNav