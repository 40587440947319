import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { Route, Routes } from "react-router-dom";
import Banner from './Banner';
import LeftNav from './LeftNav';
import MobileNav from './MobileNav';
import PageNotFound from './PageNotFound';
import DBIZManagement from './pages/DBIZManagement';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Report from './pages/Report';
import TenantManagement from './pages/TenantManagement';
import UserManagement from './pages/UserManagement';
import UserRequest from './pages/UserRequest';
import ApproverUI from './pages/ApproverUI';
import DBIZTicketManagement from './pages/DBIZTicketManagement';
import NotificationContainer from './NotificationContainer';

const box1Styles = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
    maxHeight: '100vh'
}

const box2Styles = {
    flexGrow: 1,
    overflow: 'auto',
}

const PageLayout = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [leftNavExpanded, setLeftNavExpanded] = useState(false);

    return (
        <Box sx={box1Styles}>
            <NotificationContainer></NotificationContainer>
            <Banner setLeftNavExpanded={setLeftNavExpanded} />
            {isMobile && <MobileNav />}
            {!isMobile && <LeftNav setLeftNavExpanded={setLeftNavExpanded} leftNavExpanded={leftNavExpanded} />}
            <Box sx={box2Styles} >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="reports/:id" element={<Report />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="usermanagement" element={<UserManagement />} />
                    <Route path="tenantmanagement" element={<TenantManagement />} />
                    <Route path="dbizmanagement" element={<DBIZManagement />} />
                    <Route path="userrequest" element={<UserRequest />} />
                    <Route path="requestapproval" element={<ApproverUI />} />
                    <Route path="dbizreqmanagement" element={<DBIZTicketManagement />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Box>
        </Box>
    )
}

export default PageLayout