import { useIsAuthenticated } from "@azure/msal-react";
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import DataLoading from './../DataLoading';

const paperStyles = {
    color: 'white',
    backgroundColor: '#0d4557',
    padding: 2,
    textAlign: 'center',
    margin: '7px',
    boxShadow: '0 15px 14px rgb(0 0 0 / 0.3)'
}

const indicatorStyles = {
    marginBottom: 1,
    textAlign: 'center',
    paddingTop: '4%',
    fontWeight: 'bolder'
}

const highlightStyles = {
    color: '#30dea3',
    fontWeight: "600"
}

const subTextStyles = {
    textAlign: 'center',
    paddingBottom: '4%'
}

const alertStyles = {
    border: 1,
    padding: 2,
    mx: 2
}

const welcomeTextStyles = {
    my: 3
}

const Home = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { embeddingData, setDisplayMainLoader } = useContext(AppContext);
    if (!isAuthenticated) {
        return (
            <Container maxWidth={false}>
                <Paper sx={paperStyles}>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5, ease: 'easeInOut' }}
                        >
                            <Typography variant="h4" component="h1" sx={indicatorStyles}>
                                Welcome to <span style={highlightStyles}>DBIZ</span> Portal
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={subTextStyles}
                            >
                                Click on the login link in the upper right corner to get started.
                            </Typography>
                        </motion.div>
                    </AnimatePresence>
                </Paper>
            </Container>
        )
    }

    if (isAuthenticated && embeddingData.user && !embeddingData.tenantName) {
        return (
            <Container maxWidth={false}>
                <Typography variant='h5' component="h2" sx={welcomeTextStyles} >Welcome to <span style={highlightStyles}>DBIZ</span> Portal</Typography>
                <Alert sx={alertStyles} severity='warning' >You user account has not been assigned to a tenant. You will
                    not have access to any reports until your user account has been assigned to a tenant.</Alert>
            </Container>
        )
    }

    if (embeddingData.workspaceArtifactsLoading) {
        setDisplayMainLoader(true);
        return <DataLoading></DataLoading>
    }


    if (isAuthenticated && embeddingData.workspaceArtifactsLoading === false && embeddingData.redirectUserToReport === true) {
        setDisplayMainLoader(false);
        if (embeddingData.reports.length > 0) {
            navigate("/reports/" + embeddingData.pageLoadReportId);
        }
        else {
            return (
                <Container maxWidth={false}>
                    <Typography variant='h5' component="h2" sx={welcomeTextStyles} >Welcome to <span style={highlightStyles}>DBIZ</span> Portal</Typography>
                    <Alert sx={alertStyles} severity='warning' >Your user account does not have any reports assigned.</Alert>
                </Container>
            )
        }
    }
}

export default Home;